import {
  message, notification, Button, Space, ConfigProvider, Radio, Checkbox,
  Row, Col, Image, Tabs, TabPane, Form, FormItem, Input, InputPassword,
  InputGroup, Menu, MenuItem, Dropdown, Card, Divider, Modal, Table, Select,
  SelectOption, DatePicker, Pagination, Upload, RangePicker, Popconfirm, Tooltip,
  Empty, Popover, SubMenu, Affix, InputNumber
} from 'ant-design-vue'
import { App } from 'vue'
const AntDesign = {
  install: function (Vue: App) {
    Vue.component('a-message', message);
    Vue.component('a-notification', notification);
    Vue.component('a-button', Button);
    Vue.component('a-space', Space);
    Vue.component('a-config-provider', ConfigProvider);
    Vue.component('a-radio', Radio);
    Vue.component('a-checkbox', Checkbox);
    Vue.component('a-row', Row);
    Vue.component('a-col', Col);
    Vue.component('a-image', Image);
    Vue.component('a-tabs', Tabs);
    Vue.component('a-tab-pane', TabPane);
    Vue.component('a-form', Form);
    Vue.component('a-form-item', FormItem);
    Vue.component('a-input', Input);
    Vue.component('a-input-password', InputPassword);
    Vue.component('a-input-group', InputGroup);
    Vue.component('a-menu', Menu);
    Vue.component('a-menu-item', MenuItem);
    Vue.component('a-dropdown', Dropdown);
    Vue.component('a-card', Card);
    Vue.component('a-divider', Divider);
    Vue.component('a-modal', Modal);
    Vue.component('a-table', Table);
    Vue.component('a-select', Select);
    Vue.component('a-select-option', SelectOption);
    Vue.component('a-date-picker', DatePicker);
    Vue.component('a-pagination', Pagination);
    Vue.component('a-upload', Upload);
    Vue.component('a-range-picker', RangePicker);
    Vue.component('a-popconfirm', Popconfirm);
    Vue.component('a-tooltip', Tooltip);
    Vue.component('a-empty', Empty);
    Vue.component('a-popover', Popover);
    Vue.component('a-sub-menu', SubMenu);
    Vue.component('a-affix', Affix);
    Vue.component('a-input-number', InputNumber);

  }
}

export default AntDesign