<template>
  <div>
    <a-popover>
      <div class="user-avatar">
        <a @click.prevent style="cursor: pointer">
          <a-image
            src="https://img.onehome.cn/new-hr-portal/5aS05YOP.png"
            height="32px"
            width="32px"
            :preview="false"
          />
        </a>
        <div class="user-avatar-name">{{ petName }}</div>
      </div>

      <template #content>
        <a-menu slot="overlay" style="border: none" mode="vertical">
          <a-sub-menu
            key="sub1"
            title="切换合同"
            v-if="userContractList.length > 1"
          >
            <a-menu-item v-for="(item, index) in userContractList" :key="index">
              <div class="user-item-c" @click="selectContract(item.userId)">
                <span style="font-weight: bold; margin-right: 12px">
                  {{ item.icon }}</span
                >{{ item.contractName }}
              </div>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="5">
            <a href="javascript:;" @click="changePassword">
              <div class="user-item-c">重置下载密码</div>
            </a>
          </a-menu-item>
          <a-menu-item key="6">
            <a href="javascript:;" @click="handleLogout">
              <div class="user-item-c">退出登录</div>
            </a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-popover>
  </div>
  <a-modal
    v-model:open="showModal"
    title="确认切换"
    :bodyStyle="{
      fontSize: '16px',
      height: '60px',
    }"
    @ok="handleOk"
  >
    确认要切换管理合同吗?
  </a-modal>
  <update-password-modal ref="updatePasswordModal" />
</template>

<script setup lang="ts">
import UpdatePasswordModal from "../UpdatePasswordModal.vue";
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import { ref, onMounted, reactive } from "vue";
import { handleChangeUser } from "@/api/login";
import { UserOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
const updatePasswordModal = ref(null);
const handleLogout = () => {
  store.dispatch("logOut");
};
const showModal = ref(false);
const petName = ref("");
const changeUserId = ref(0);
const userContractList = ref([]);
const userId = ref(0);

onMounted(() => {
  petName.value = localStorage.getItem("PETNAME")
    ? localStorage.getItem("PETNAME")
    : "";
  if (localStorage.getItem("userContractList")) {
    userContractList.value = JSON.parse(
      localStorage.getItem("userContractList")
    );
    userId.value = JSON.parse(localStorage.getItem("contractUserId"));
    userContractList.value.map((item: any) => {
      if (item.userId == userId.value) {
        item.icon = "√";
      }
    });
  }
});
const changePassword = () => {
  if (updatePasswordModal.value) updatePasswordModal.value.showModal();
};

const handleOk = () => {
  handleChangeUser({ userId: changeUserId.value }).then((res: any) => {
    if (res.code == 200) {
      localStorage.setItem(
        "contractUserId",
        JSON.stringify(changeUserId.value)
      );
      let params = {
        token: res.data.token,
        petName: res.data.petName,
      };
      changeUserId.value = 0;
      store.dispatch("login", params);
    }
  }).catch;
};
//切换合同
const selectContract = (id: number) => {
  if (userId.value == id) {
    message.info("不能切换至当前合同");
    return;
  }
  showModal.value = true;
  changeUserId.value = id;
};
</script>

<style lang="scss" scoped>
.user-item-c {
  font-size: 16px;
  color: #42b983;
}
.user-avatar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .user-avatar-name {
    margin-left: 16px;
    color: #ffffff;
  }
}
:deep(.ant-menu-item-selected) {
  border-radius: 6px !important;
}

:deep(.ant-menu-title-content) {
  font-size: 16px;
  color: #42b983;
}

:deep(.ant-menu-submenu-arrow) {
  color: #42b983;
}

:deep(.ant-modal .ant-modal-footer) {
  text-align: center !important;
  display: flex;
  justify-content: space-around;
}
</style>