import { axios } from '@/utils/request'
import api from './index'

export function getDataStatisticsIds(parameter: object) {
  return axios({
    url: api.getDataStatisticsIds,
    method: 'post',
    data: parameter
  })
}

