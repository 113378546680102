<template>
  <div
    style="max-width: 1440px; margin: 0 auto; margin-top: 32px; height: 800px"
  >
    <iframe
      :src="iframeUrl"
      width="100%"
      height="800px"
      scrolling="auto"
      style="border: none; overflow: auto"
    />
  </div>
</template>

<script lang="ts" setup>
//弹福
import { getDataStatisticsIds } from "@/api/dataStatistics";
import { onMounted, ref } from "vue";
import { encryptedData, decryptedData } from "@/utils/rsa";
const iframeUrl = ref("https://bi.onehome.cn/link/y4QlmZ0n");
onMounted(() => {
  getIds();
});

const getIds = async () => {
  let res: any = await getDataStatisticsIds({});
  if (res.code == 200) {
    let params = `{"contract_id":${res.data.contractId},"customer_id":${res.data.customerId}}`;
    let encryptedParams = encodeURIComponent(encryptedData(params));
    iframeUrl.value = iframeUrl.value + "?attachParams=" + encryptedParams;
  }
};
</script>

<style lang="scss" scoped>
iframe {
  overflow: auto;
}
</style>