import { ACCESS_TOKEN } from "@/config/config";
import { loginApi } from '@/api/login'
import router from "@/router";
import { message } from "ant-design-vue";
import { generateRoutes } from "@/utils/router";
import store from "@/store";


const user = {
  state: {
    token: '',
    petName: '',
  },
  getters: {
    GET_PETNAME(state: any) {
      return state.petName
    },
  },
  mutations: {
    SET_TOKEN(state: any, token: string) {
      state.token = token
    },
    SET_PETNAME(state: any, petName: string) {
      state.petName = petName
    }
  },
  actions: {
    login(context: any, userInfo: any) {
      context.commit('SET_TOKEN', userInfo.token)
      localStorage.setItem(ACCESS_TOKEN, userInfo.token)
      localStorage.setItem('PETNAME', userInfo.petName)
      message.success('登录成功')
      location.reload()
    },
    loginByOtherContract(context: any, userInfo: any) {
      context.commit('SET_TOKEN', userInfo.token)
      localStorage.setItem(ACCESS_TOKEN, userInfo.token)
      localStorage.setItem('PETNAME', userInfo.petName)
      message.success('登录成功,请选择对应合同')
    },
    logOut(context: any) {
      return new Promise((resolve) => {
        localStorage.removeItem(ACCESS_TOKEN)
        context.commit('SET_TOKEN', '')
        router.push({ name: 'login' })
        localStorage.removeItem("userContractList");
        resolve(true)
      })
    }
  },


}

export default user
