// 普通的就是 import JsEncrypt from 'jsencrypt'
import JSEncrypt from 'jsencrypt'

const publicKey: string = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCxt2BuKKXRJhRgYvC/qfj6/L32
VGgk45giVs+zHQJ3EmmKVZQ/kI/nu9MQO+VgjbWzTnqtJpg+NwjfxCEMkKVCW9qN
xMbJZntlrD6D37Op+d4iAhQm9Tgm+tkd6uCJ3D8Q8uQw6rBTdiShwifQnbyzRxMM
7UALOkrLSz5z79chiwIDAQAB
-----END PUBLIC KEY-----`
const privateKey = `-----BEGIN PRIVATE KEY-----
MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALG3YG4opdEmFGBi
8L+p+Pr8vfZUaCTjmCJWz7MdAncSaYpVlD+Qj+e70xA75WCNtbNOeq0mmD43CN/E
IQyQpUJb2o3Exslme2WsPoPfs6n53iICFCb1OCb62R3q4IncPxDy5DDqsFN2JKHC
J9CdvLNHEwztQAs6SstLPnPv1yGLAgMBAAECgYBVG0Vx28N1ArB1bExw7RxQTiNG
yStKpzffEgXLeBQLI+UpG0ywmDFiP5+XQafacmLWqLm1HCuCd+anCoztMIQDEzFN
MrJoIZdBVQDcAvwQZjNfpnStmM+HdZ27yLLFWTG269TCL6duNzRJFr2y5MHtIzDS
apGziykOBHi+Y+c2uQJBAOq0OpicAQmOI7NhGbiCMTcCnLCTWE6B6+FAUyg2gaFK
LqutmZ3+ECsbeU3JwKxDNJtegti83bsKT2dePsP3ZSUCQQDB12vc8Za+1EpdAxcz
OU6nXv4hK/GfmR3+11GKCyeDA03ab/DqIhXBwvNL9edFGWSHAAiOQmElln7c8ct4
hKTvAkEAlbeugVdS2hOP9oeEbsyuNFc4OPNlK395RtFPuqE6g/bcWqt9COneyoDW
GqcOf1THpCzf7v5bu0rSaDAYfc28+QJAEt1OKABy9TtfyVGFrnyDRRKflMbcCvYx
zx1RWCfhvoRRzVJblc2fZG3+k4Po7/aaq/C65G1dWvUcf5AghygRjQJBALdFtYEb
3rU2Nj576pNqqNov1KkbhIDhLZuykX46s5QazGHT92Z7xYlwz03Rcmcms5KXSkYD
FmBVYcBJj5m9RN4=
-----END PRIVATE KEY-----`

/**
 * 最长加密长度
 */
const MAX_ENCRYPT_BLOCK = 117
/**
 * 最长解码长度
 */
const MAX_DECRYPT_BLOCK = 128

// 加密
// encrypt(JSON.stringify({ name: '', password: '' }))看加密的是字符串还是对象
export function encryptedData(msg: any) {
  const jsencrypt = new JSEncrypt()
  jsencrypt.setPublicKey(publicKey)

  const chunkSize = MAX_ENCRYPT_BLOCK; // 默认分段长度为117
  const textLen = msg.length; // 待加密文本长度

  let offset = 0; // 分段偏移量
  let encrypted = ''; // 加密结果
  // 分段加密
  while (offset < textLen) {
    let chunk = msg.substr(offset, chunkSize); // 提取分段数据
    let enc = jsencrypt.encrypt(chunk); // 加密分段数据
    encrypted += enc; // 连接加密结果
    offset += chunkSize; // 更新偏移量
  }
  return encrypted
}


// 解密
export function decryptedData(msg: any) {
  let decrypt = new JSEncrypt()
  decrypt.setPrivateKey(`-----BEGIN RSA PRIVATE KEY-----${privateKey}-----END RSA PRIVATE KEY-----`)
  var decryptMsg = decrypt.decrypt(msg)
  return decryptMsg
}
