import { createStore } from 'vuex'
import user from './modules/user';
import routes from './modules/routes'
import modal from './modules/modal'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    routes,
    modal
  }
})
