<template>
  <div class="header-body">
    <div class="header-layout">
      <div>
        <a-row>
          <a-col style="display: flex; align-items: center">
            <a-image
              src="https://img.onehome.cn/new-hr-portal/bG9nb%2BeZveiJsg%3D%3D.png"
              :preview="false"
            />
          </a-col>
          <a-col>
            <a-menu
              theme="dark"
              class="menu-c"
              :selectedKeys="[
                route.name == 'posBatchAdd' || route.name == 'posBatchReduce'
                  ? 'posList'
                  : route.name,
              ]"
              mode="horizontal"
              :items="items"
              @click="handleClickMenu"
            >
              <!-- <a-menu-item v-for="item in items" :key="item.key"
          >{{ item.label }}
          <router-link :to="{ name: item.key }"></router-link>
        </a-menu-item>-->
            </a-menu>
          </a-col>
        </a-row>
      </div>

      <div class="user-menu-c">
        <user-menu />
      </div>
    </div>
  </div>
  <router-view />
</template>

<script lang="ts" setup>
import { h, ref } from "vue";
import { MenuProps } from "ant-design-vue";
import { useStore } from "vuex";
import { UserMenu } from "./index";
import { Menu } from "@/config/config";
import router from "@/router";
import { useRoute } from "vue-router";
const route = useRoute();
const store = useStore();
const routes = store.getters.GET_ROUTE;

// const items = ref<MenuProps["items"]>([]);
const items = ref([]);

routes.map((e: any) => {
  if (e.name in Menu) {
    if (e.children) {
      let newChildren: Array<any> = [];
      e.children.map((foo: any) => {
        newChildren.push({
          key: foo.name,
          label: Menu[foo.name],
          title: Menu[foo.name],
        });
      });
      items.value.push({
        key: e.name,
        label: Menu[e.name],
        title: Menu[e.name],
        children: newChildren,
      });
    } else {
      items.value.push({
        key: e.name,
        label: Menu[e.name],
        title: Menu[e.name],
      });
    }
  }
});

const handleClickMenu: MenuProps["onClick"] = (menuInfo) => {
  router.push({ name: menuInfo.key });
};
</script>

<style lang="scss" scoped>
.header-body {
  width: 100%;
  background-color: #001529;
}
.header-layout {
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  /* Safari */
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;

  .menu-c {
    padding: 0 0 0 80px;
    font-size: 14px;
    max-width: 800px;
    width: 100%;
    font-weight: 900;
  }
  .user-menu-c {
    height: 100%;
    max-width: 440px;
  }
}
.ant-menu-item {
  padding-inline: 36px;
}
</style>