<template>
  <div>
    <a-modal
      v-model:open="open"
      title="重置下载密码"
      width="600px"
      okText="确定"
      cancelText="取消"
      :footer="null"
    >
      <a-form
        ref="formRef"
        :model="formdata"
        style="width: 400px; margin: 0 auto"
        v-bind="{
          labelCol: { span: 5 },
          wrapperCol: { span: 16 },
        }"
        :rules="rules"
        @finish="handleSubmit"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="newPassword" label="新密码">
          <a-input v-model:value="formdata.newPassword"></a-input>
        </a-form-item>
        <a-form-item name="confirmPassword" label="确认新密码">
          <a-input v-model:value="formdata.confirmPassword"></a-input>
        </a-form-item>
        <div style="color: #000000a6; font-size: 12px; margin-bottom: 16px">
          初始文件密码为 123456。如更新密码，请重新导出文件并用新密码打开
        </div>
        <div style="text-align: center">
          <a-button type="primary" html-type="submit">提交修改</a-button>
        </div>
      </a-form>
      <!-- <a-card style="max-width: 1440px; margin: 0 auto; margin-top: 32px">
      </a-card>-->
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, reactive, onMounted } from "vue";
import type { Rule } from "ant-design-vue/es/form";
import moment from "moment";
import { useRoute } from "vue-router";
import { FormInstance, message } from "ant-design-vue";
import { changePassword, getUserInfo } from "@/api/login";

const open = ref<boolean>(false);
interface FormData {
  newPassword: string;
  confirmPassword: string;
}
const formRef = ref<FormInstance>();
const formdata = reactive<FormData>({
  newPassword: "",
  confirmPassword: "",
});
const rules: Record<string, Rule[]> = {
  endTime: [{ required: true, message: "请选择减保日期" }],
};
const isDefaultPassword = ref(true);
const showModal = () => {
  open.value = true;
  getUserInfoDetail();
};
const getUserInfoDetail = async () => {
  let res: any = await getUserInfo({});
  if (res.code == 200) {
    if (res.data.lastOfpChangeTime != null) {
      isDefaultPassword.value = false;
    }
  }
};
const handleSubmit = (values: any) => {
  try {
    if (values.confirmPassword != values.newPassword) {
      throw new Error("请确保两次密码一致");
    }
    let params = {
      newPassword: values.newPassword,
      type: "DOWNLOAD_FILE",
    };
    changePassword(params)
      .then((res: any) => {
        if (res.code == 200) {
          message.success("修改成功");
          open.value = false;
        } else {
          message.error(res.msg);
        }
      })
      .catch(() => {});
  } catch (error: any) {
    message.error(error.message);
  }
};
const handleFinishFailed = (errors: any) => {
  console.log(
    "%c [ errors ]-188",
    "font-size:13px; background:pink; color:#bf2c9f;",
    errors
  );
};
defineExpose({
  showModal,
});
</script>

<style lang="scss" scoped>
</style>