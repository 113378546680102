import { axios } from '@/utils/request'
import api from './index'

export function getFlexEmployeeList(parameter: object) {
  return axios({
    url: api.getFlexEmployeeList,
    method: 'post',
    data: parameter
  })
}


export function exportFlexEmployeeList(parameter: object) {
  return axios({
    url: api.exportFlexEmployeeList,
    method: 'post',
    data: parameter
  })
}

export function getBoughtRecordList(parameter: object) {
  return axios({
    url: api.getBoughtRecordList,
    method: 'post',
    data: parameter
  })
}