<template>
  <div>
    <a-modal
      v-model:open="open"
      width="1200px"
      okText="确定"
      cancelText="取消"
      :footer="null"
    >
      <a-tabs v-model:activeKey="activeKey" @change="onchangeTabs">
        <a-tab-pane key="effective" tab="有效购买记录">
          <a-table
            :dataSource="dataSourceByEffective"
            style="margin-top: 4px"
            :columns="columnsA"
            :loading="loading"
          >
            <template #bodyCell="{ column, record }">
              <template
                v-if="column.key === 'fileName' && record.state == 'SUCCESS'"
              >
                <a style="color: #42b983" @click="handleDownload(record)">{{
                  record.fileName
                }}</a>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="invalid" tab="失效购买记录">
          <a-table
            :dataSource="dataSourceByInvalid"
            style="margin-top: 4px"
            :columns="columnsB"
            :loading="loading"
          >
            <template #bodyCell="{ column, record }">
              <template
                v-if="column.key === 'fileName' && record.state == 'SUCCESS'"
              >
                <a style="color: #42b983" @click="handleDownload(record)">{{
                  record.fileName
                }}</a>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, reactive, onMounted } from "vue";
import dayjs, { Dayjs } from "dayjs";
import type { Rule } from "ant-design-vue/es/form";
import moment from "moment";
import { useRoute } from "vue-router";
import { FormInstance, message } from "ant-design-vue";
import { exportRecordList } from "@/api/pos";
import { getBoughtRecordList } from "@/api/flex";
import { getDownloadUrl } from "@/api/home";
const open = ref<boolean>(false);
const activeKey = ref<string>("effective");
interface DataObject {
  value: any;
}
const recordData: DataObject = ref(null);
const showModal = (paramater: DataObject) => {
  dataSourceByEffective.value = [];
  dataSourceByInvalid.value = [];
  activeKey.value = "effective";
  open.value = true;
  recordData.value = paramater;
  getTableData("effective");
};

const handleSubmit = (values: any) => {};
const handleFinishFailed = (errors: any) => {};
defineExpose({
  showModal,
});
const dataSourceByEffective = ref<Array<any>>([]);
const dataSourceByInvalid = ref<Array<any>>([]);

const loading = ref<boolean>(false);
const route = useRoute();
const checkPosDetailInfo = ref(null);
const currentPage = ref(1);
const columnsA = ref<Array<any>>([
  {
    title: "商品名称",
    dataIndex: "skuName",
    key: "skuName",
  },
  {
    title: "总价",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
  {
    title: "企业支付方式",
    dataIndex: "enterprisePayTypeZh",
    key: "enterprisePayTypeZh",
  },
  {
    title: "企业支付金额",
    dataIndex: "enterprisePayAmount",
    key: "enterprisePayAmount",
  },
  {
    title: "个人支付方式",
    dataIndex: "selfPayTypeZh",
    key: "selfPayTypeZh",
  },
  {
    title: "个人支付金额",
    dataIndex: "selfPayAmount",
    key: "selfPayAmount",
  },
  {
    title: "被保险人姓名",
    dataIndex: "insuredName",
    key: "insuredName",
  },
  {
    title: "被保险人证件号",
    dataIndex: "insuredIdNum",
    key: "insuredIdNum",
  },
  {
    title: "订单时间",
    dataIndex: "orderCreatedAt",
    key: "orderCreatedAt",
  },
]);
const columnsB = ref<Array<any>>([
  {
    title: "商品名称",
    dataIndex: "skuName",
    key: "skuName",
  },
  {
    title: "总价",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
  {
    title: "企业支付方式",
    dataIndex: "enterprisePayTypeZh",
    key: "enterprisePayTypeZh",
  },
  {
    title: "企业支付金额",
    dataIndex: "enterprisePayAmount",
    key: "enterprisePayAmount",
  },
  {
    title: "个人支付方式",
    dataIndex: "selfPayTypeZh",
    key: "selfPayTypeZh",
  },
  {
    title: "个人支付金额",
    dataIndex: "selfPayAmount",
    key: "selfPayAmount",
  },
  {
    title: "被保险人姓名",
    dataIndex: "insuredName",
    key: "insuredName",
  },
  {
    title: "被保险人证件号",
    dataIndex: "insuredIdNum",
    key: "insuredIdNum",
  },
  {
    title: "订单状态",
    dataIndex: "stateZh",
    key: "stateZh",
  },
  {
    title: "失效时间",
    dataIndex: "orderUpdatedAt",
    key: "orderUpdatedAt",
  },
]);

const getTableData = async (key: "effective") => {
  loading.value = true;
  let params = {
    accountCenterId: recordData.value.accountCenterId,
    employeeId: recordData.value.employeeId,
    orderDetailStateSet: [] as string[], // 明确指定 orderDetailStateSet 是一个字符串数组
  };
  if (key == "effective") {
    params.orderDetailStateSet.push("PAID");
  } else {
    params.orderDetailStateSet.push("UNPAID");
    params.orderDetailStateSet.push("REFUNDING");
    params.orderDetailStateSet.push("INVALID");
    params.orderDetailStateSet.push("REFUNDED");
  }
  let res: any = await getBoughtRecordList(params);
  if (res.code == 200) {
    loading.value = false;
    key == "effective"
      ? (dataSourceByEffective.value = res.data)
      : (dataSourceByInvalid.value = res.data);
  } else {
    message.error(res.msg);
  }
};

const onchangeTabs = (activeKey: any) => {
  getTableData(activeKey);
};

const handleDownload = async (record: any) => {
  let params = {
    id: record.attachmentId,
    duration: 60 * 10,
  };
  let res = await getDownloadUrl(params);
  let a = document.createElement("a");
  a.href = res.data;
  a.click();
};
</script>

<style lang="scss" scoped>
</style>