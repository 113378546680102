<template>
  <div>
    <a-modal
      v-model:open="open"
      title="变更层级"
      width="600px"
      okText="取消"
      cancelText="取消"
      :footer="null"
    >
      <a-form
        ref="formRef"
        :model="formdata"
        style="width: 400px; margin: 0 auto"
        v-bind="{
          labelCol: { span: 7 },
          wrapperCol: { span: 16 },
        }"
        :rules="rules"
        @finish="handleSubmit"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="insuredName" label="姓名">
          <a-input
            v-model:value="detailInfo.insuredName"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item name="insuredIdType" label="证件类型">
          <a-select
            ref="select"
            v-model:value="detailInfo.insuredIdType"
            :disabled="true"
          >
            <a-select-option v-for="foo in idTypeDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="insuredIdNum" label="证件号">
          <a-input
            v-model:value="detailInfo.insuredIdNum"
            :disabled="true"
          ></a-input>
        </a-form-item>

        <a-form-item name="relation" label="人员类别">
          <a-select
            ref="select"
            v-model:value="detailInfo.relation"
            :disabled="true"
          >
            <a-select-option v-for="foo in relationDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="planPackageId" label="保障方案">
          <a-select
            ref="select"
            v-model:value="detailInfo.planPackageId"
            :disabled="true"
          >
            <a-select-option v-for="foo in planPackageDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="newPlanPackageId" label="变更后保障方案">
          <a-select ref="select" v-model:value="formdata.newPlanPackageId">
            <a-select-option v-for="foo in planPackageDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="newStartTime" label="变更后生效日期">
          <a-date-picker
            v-model:value="formdata.newStartTime"
            placeholder=""
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 3, span: 12 }">
          <a-button type="primary" html-type="submit" style="width: 300px"
            >提交</a-button
          >
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, reactive } from "vue";
import dayjs, { Dayjs } from "dayjs";
import type { Rule } from "ant-design-vue/es/form";
import moment from "moment";
import { FormInstance, message } from "ant-design-vue";
import { changePlan } from "@/api/pos";
const open = ref<boolean>(false);
const dateFormat = "YYYY-MM-DD";
const props = defineProps({
  idTypeDicList: Array,
  planPackageDicList: Array,
  genderDicList: Array,
  bankDicList: Array,
  branchDicList: Array,
  relationDicList: Array,
});
const emits = defineEmits(["refresh"]);
const {
  idTypeDicList,
  planPackageDicList,
  genderDicList,
  bankDicList,
  branchDicList,
  relationDicList,
} = toRefs(props);
const detailInfo = ref<any>();
interface FormData {
  newPlanPackageId: string;
  newStartTime: string;
}
const formRef = ref<FormInstance>();
const formdata = reactive<FormData>({
  newPlanPackageId: "",
  newStartTime: "",
});
const rules: Record<string, Rule[]> = {
  newPlanPackageId: [{ required: true, message: "请选择变更后保障方案" }],
  newStartTime: [{ required: true, message: "请选择变更后生效日期" }],
};
const birthDate = ref<Dayjs>();
const startDate = ref<Dayjs>();
const showModal = (record: any) => {
  detailInfo.value = record;
  open.value = true;
};

const handleSubmit = (values: any) => {
  values.newStartTime = dayjs(values.newStartTime).format("YYYY-MM-DD");
  values.posId = detailInfo.value.id;
  changePlan(values)
    .then((res: any) => {
      if (res.code == 200) {
        message.success("变更层级成功");
        emits("refresh");
        open.value = false;
      } else {
        message.error(res.msg);
      }
    })
    .catch();
};
const handleFinishFailed = (errors: any) => {
  console.log(
    "%c [ errors ]-188",
    "font-size:13px; background:pink; color:#bf2c9f;",
    errors
  );
};
defineExpose({
  showModal,
});
</script>

<style lang="scss" scoped>
</style>