export const ACCESS_TOKEN: string = 'HrPortal-Access-Token'

export const Menu = {
  home: '首页',
  dataStatistics: '数据统计',
  claimDataAnalyse: '理赔数据分析',
  policyPerspective: '保单透视',
  medicalMap: '就医地图',
  flexDataCheck: '弹福记录',
  flexDataDashboard: '弹福统计',
  flexDataBenchmark: '福利数据对标',
  claimInquiry: '理赔查询',
  posList: '人员管理',
  posBatchAdd: '批量增人',
  posBatchReduce: '批量减人',
  operationLog: '操作日志'
}

export const insurancce_liability = {
  'DISEASE_CLINIC': '综合门诊',
  'HOSPITALIZATION': '综合住院',
  'HOSPITALIZATION_FOR_SERIOUS_ILLNESS': '大病住院医疗',
  'CHILDBIRTH': '生育',
  'ACCIDENTAL_MEDICAL_HOSPITALIZATION': '意外医疗住院',
  'ACCIDENT_MEDICAL_CLINIC': '意外医疗门诊',
  'ACCIDENT': '意外身故',
  'ACCIDENT_DISABILITY': '意外伤残',
  'TERM_LIFE_INSURANCE': '疾病身故',
  'TERM_LIFE_INSURANCE_DISABILITY': '疾病全残',
  'SERIOUS_ILLNESS': '重疾',
  'SERIOUS_ILLNESS_MEDICAL': '重疾医疗',
  'TRAFFIC_ACCIDENT': '交通意外',
  'HOSPITALIZATION_ALLOWANCE': '住院津贴',
  'PUBLIC_SECURITY': '公共保障',
  'UMBILICAL_CORD_BLOOD_TRANSPLANTATION': '脐血移植医疗',
  'REINFUSION_REHABILITATION_TREATMENT': '回输后康复治疗',
}

export const liabilityGroup = {
  DISEASE_CLINIC: '',
  HOSPITALIZATION: '',
  HOSPITALIZATION_FOR_SERIOUS_ILLNESS: '',
  CHILDBIRTH: '',
  ACCIDENTAL_MEDICAL_HOSPITALIZATION: '',
  ACCIDENT_MEDICAL_CLINIC: '',
  SERIOUS_ILLNESS_MEDICAL: '',
}

export const dataStatisticsChildrenRoutes = {
  claimDataAnalyse: '理赔数据分析',
  policyPerspective: '保单透视',
  medicalMap: '就医地图',
  flexDataDashboard: '弹福统计',
  flexDataBenchmark: '福利数据对标',
}