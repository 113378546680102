import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/reset.css';
import AntDesign from './plugins/ant';
import './permission'
import echarts from '@/plugins/echarts'
import './style/global.scss'

const app = createApp(App);

app.config.globalProperties.$echarts = echarts
app.use(store).use(AntDesign).use(router).mount('#app');