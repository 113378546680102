<template>
  <a-card style="max-width: 1440px; margin: 0 auto; margin-top: 32px">
    <div>
      <a-form
        ref="formRef"
        :model="formState"
        @finish="handleSearch"
        @finishFailed="handleFinishFailed"
        style="text-align: left"
      >
        <a-row :gutter="24">
          <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
            <a-form-item label="案件状态：" name="state">
              <a-select v-model:value="formState.state" placeholder="请选择">
                <a-select-option
                  v-for="foo in claimStateDicList"
                  :key="foo.code"
                  >{{ foo.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
            <a-form-item label="申请时间段：" name="collectionDateFrom">
              <a-range-picker
                v-model:value="formState.collectionDateFrom"
                :placeholder="['开始日期', '结束日期']"
                @change="onChangeRangePicker"
              />
            </a-form-item>
          </a-col>
          <template v-if="openMore">
            <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
              <a-form-item label="责任类型：" name="liabilityType">
                <a-select
                  v-model:value="formState.liabilityType"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="foo in liabilityTypeDicList"
                    :key="foo.code"
                    >{{ foo.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </template>
          <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="{ float: 'right', overflow: 'hidden' } || {}"
            >
              <a-button
                style="margin-left: 20px; width: 90px"
                type="primary"
                html-type="submit"
                >查询</a-button
              >
              <a-button style="margin-left: 16px; width: 90px" @click="Reset()"
                >重置</a-button
              >
              <a
                @click="
                  () => {
                    openMore = !openMore;
                  }
                "
                style="margin-left: 8px; color: #42b983"
              >
                {{ openMore ? "收起" : "展开" }}
                <DownOutlined v-if="!openMore" />
                <UpOutlined v-else />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="button-collection-r">
      <a-button type="primary" @click="handleExport">
        <ExportOutlined />导出理赔记录
      </a-button>
      <a-button class="button-c" type="default" @click="showExportModal">
        <BarsOutlined />导出记录</a-button
      >
    </div>
    <a-table
      style="margin-top: 16px"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{
        current: currentPage,
        onChange: onChangePage,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: totalData,
        pageSize: pageSizeConfig,
        showTotal: (totalData) => `共 ${totalData} 条数据`,
      }"
      :loading="loading"
    >
      <template #bodyCell="{ column, text, record }">
        <span v-if="column.key === 'payoutAmount'">
          {{ fmoney(record.payoutAmount) }}
        </span>
      </template>
    </a-table>
  </a-card>
  <claim-export-record-modal ref="claimExportRecordModal" />
</template>

<script lang="ts" setup>
import { ref, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { fmoney } from "@/utils/tools";
import { getDic } from "@/api/pos";
import { claimInquiryList, claimExport } from "@/api/claim";
import {
  DownOutlined,
  UpOutlined,
  DownloadOutlined,
  BarsOutlined,
  ExportOutlined,
} from "@ant-design/icons-vue";
import ClaimExportRecordModal from "./modules/ClaimExportRecordModal.vue";
import { FormInstance, message } from "ant-design-vue";
import { debounce } from "@/utils/tools";
const dataSource = ref<Array<any>>([]);
const loading = ref<boolean>(false);
const route = useRoute();
const checkPosDetailInfo = ref(null);
const currentPage = ref(1);
const pageSizeConfig = ref(10);
const totalData = ref(0);
const claimExportRecordModal = ref(null);
const claimStateDicList = ref<Array<any>>([]);
const liabilityTypeDicList = ref<Array<any>>([]);
const collectionDateFrom = ref<string>("");
const collectionDateTo = ref<string>("");
const openMore = ref<boolean>(false);
interface FormState {
  state: string | null;
  liabilityType: string | null;
  collectionDateFrom: string | null;
  collectionDateTo: string | null;
}
const formRef = ref<FormInstance>();
const formState = reactive<FormState>({
  state: null,
  liabilityType: null,
  collectionDateFrom: null,
  collectionDateTo: null,
});
const columns = ref<Array<any>>([
  {
    title: "理赔ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "被保险人 ID ",
    dataIndex: "userInsurerId",
    key: "userInsurerId",
  },
  {
    title: "人员类型",
    dataIndex: "relationZh",
    key: "relationZh",
  },
  {
    title: "案件申请时间",
    dataIndex: "collectionDate",
    key: "collectionDate",
  },
  {
    title: "责任类型",
    dataIndex: "liabilityTypeZh",
    key: "liabilityTypeZh",
  },
  {
    title: "案件状态",
    dataIndex: "stateZh",
    key: "stateZh",
  },
  {
    title: "赔付金额",
    dataIndex: "payoutAmount",
    key: "payoutAmount",
  },
  {
    title: "结案日期",
    dataIndex: "caseCloseDate",
    key: "caseCloseDate",
  },
  {
    title: "拒赔/撤案原因",
    dataIndex: "paidDelayDesc",
    key: "paidDelayDesc",
    width: "500px",
  },
]);

onMounted(() => {
  getTableData();
  getDic({}).then((res) => {
    claimStateDicList.value = res.data.claimStateDicList;
    liabilityTypeDicList.value = res.data.liabilityTypeDicList;
  }).catch;
});

const getTableData = async (data: any = {}) => {
  loading.value = true;
  let res: any = await claimInquiryList(data);
  if (res.code == 200) {
    dataSource.value = res.data.dataList;
    totalData.value = res.data.total;
    currentPage.value = res.data.pageNum;
  }

  loading.value = false;
};

const showExportModal = () => {
  if (claimExportRecordModal.value) claimExportRecordModal.value.showModal();
};

const handleExport = debounce(async () => {
  let values = {
    state: formState.state,
    liabilityType: formState.liabilityType,
    collectionDateFrom: collectionDateFrom.value,
    collectionDateTo: collectionDateTo.value,
  };
  let resExport: any = await claimExport(values);
  if (resExport.code == 200) {
    message.success("导出成功，请前往导出记录查看下载");
  } else {
    message.error("导出失败");
  }
}, 500);

const handleFinishFailed = (errors: any) => {
  console.log(errors);
};

const handleSearch = (values: FormState) => {
  values.collectionDateFrom = collectionDateFrom.value;
  values.collectionDateTo = collectionDateTo.value;
  getTableData(values);
};

const onChangeRangePicker = (date: any, dateString: any) => {
  collectionDateFrom.value = dateString[0];
  collectionDateTo.value = dateString[1];
};
const onChangePage = (pageNum: number, pageSize: number) => {
  let data = {
    pageNum: pageNum,
    pageSize: pageSize,
    state: formState.state ? formState.state : null,
    liabilityType: formState.liabilityType ? formState.liabilityType : null,
    collectionDateFrom: collectionDateFrom.value
      ? collectionDateFrom.value
      : null,
    collectionDateTo: collectionDateTo.value ? collectionDateTo.value : null,
  };
  currentPage.value = pageNum;
  pageSizeConfig.value = pageSize;
  getTableData(data);
};
const Reset = () => {
  if (formRef.value) formRef.value.resetFields();
  collectionDateFrom.value = "";
  collectionDateTo.value = "";
};
</script>

<style lang="scss" scoped>
.button-collection-r {
  float: right;
  .button-c {
    margin-left: 12px;
  }
}
</style>