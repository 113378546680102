<template>
  <a-card style="max-width: 1440px; margin: 0 auto; margin-top: 32px">
    <div>
      <a-form
        ref="formRef"
        :model="formState"
        @finish="handleSearch"
        @finishFailed="handleFinishFailed"
        style="text-align: left"
      >
        <a-row :gutter="24">
          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <a-form-item label="姓名：" name="name">
              <a-input
                v-model:value="formState.name"
                placeholder="请输入姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <a-form-item label="证件号：" name="idNum">
              <a-input
                v-model:value="formState.idNum"
                placeholder="请输入证件号"
              />
            </a-form-item>
          </a-col>
          <template v-if="openMore">
            <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
              <a-form-item label="人员标签：" name="personnelLabel">
                <a-select
                  v-model:value="formState.personnelLabel"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="foo in personnelLabelList"
                    :key="foo.code"
                    >{{ foo.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
              <a-form-item label="工号：" name="jobNumber">
                <a-input
                  v-model:value="formState.jobNumber"
                  placeholder="请输入工号"
                />
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
              <a-form-item label="人员状态：" name="state">
                <a-select v-model:value="formState.state" placeholder="请选择">
                  <a-select-option
                    v-for="foo in employeeStateList"
                    :key="foo.code"
                    >{{ foo.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
              <a-form-item label="窗口截止日期范围：" name="choiceEndDateFrom">
                <a-range-picker
                  v-model:value="formState.choiceEndDateFrom"
                  :placeholder="['开始日期', '结束日期']"
                  @change="onChangeRangePicker"
                />
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
              <a-form-item label="标准积分范围">
                <a-input-number
                  style="margin-right: 4px"
                  v-model:value="formState.standardBudgetFrom"
                />--
                <a-input-number v-model:value="formState.standardBudgetTo" />
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
              <a-form-item label="剩余积分范围">
                <a-input-number
                  style="margin-right: 4px"
                  v-model:value="formState.budgetBalanceFrom"
                />--
                <a-input-number v-model:value="formState.budgetBalanceTo" />
              </a-form-item>
            </a-col>
          </template>
          <a-col :xxl="6" :xl="6" :lg="8" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="{ float: 'right', overflow: 'hidden' } || {}"
            >
              <a-button
                style="margin-left: 20px; width: 90px"
                type="primary"
                html-type="submit"
                >查询</a-button
              >
              <a-button style="margin-left: 16px; width: 90px" @click="Reset()"
                >重置</a-button
              >
              <a
                @click="
                  () => {
                    openMore = !openMore;
                  }
                "
                style="margin-left: 8px; color: #42b983"
              >
                {{ openMore ? "收起" : "展开" }}
                <DownOutlined v-if="!openMore" />
                <UpOutlined v-else />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="button-collection-r">
      <a-button type="primary" @click="handleExport">
        <ExportOutlined />导出
      </a-button>
      <a-button class="button-c" type="default" @click="showExportModal">
        <BarsOutlined />导出记录</a-button
      >
    </div>
    <a-table
      style="margin-top: 16px"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{
        current: currentPage,
        onChange: onChangePage,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: totalData,
        pageSize: pageSizeConfig,
        showTotal: (totalData) => `共 ${totalData} 条数据`,
      }"
      :loading="loading"
    >
      <template #bodyCell="{ column, text, record }">
        <span v-if="column.key === 'payoutAmount'">
          {{ fmoney(record.payoutAmount) }}
        </span>
        <span v-if="column.key === 'action'">
          <a
            @click="showModalByCheckBoughtRecordModal(record)"
            style="color: #42b983"
            >查看</a
          >
        </span>
      </template>
    </a-table>
  </a-card>
  <flex-bought-record-modal ref="flexBoughtRecordModal" />
  <flex-export-record-modal ref="flexExportRecordModal" />
</template>

<script lang="ts" setup>
import { ref, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { fmoney } from "@/utils/tools";
import { getDic } from "@/api/pos";
import { getFlexEmployeeList, exportFlexEmployeeList } from "@/api/flex";
import {
  DownOutlined,
  UpOutlined,
  DownloadOutlined,
  BarsOutlined,
  ExportOutlined,
} from "@ant-design/icons-vue";
import FlexExportRecordModal from "./modules/FlexExportRecordModal.vue";
import FlexBoughtRecordModal from "./modules/FlexBoughtRecordModal.vue";
import { FormInstance, message } from "ant-design-vue";
import { debounce } from "@/utils/tools";
const dataSource = ref<Array<any>>([]);
const loading = ref<boolean>(false);
const route = useRoute();
const checkPosDetailInfo = ref(null);
const currentPage = ref(1);
const pageSizeConfig = ref(10);
const totalData = ref(0);
const flexExportRecordModal = ref(null);
const flexBoughtRecordModal = ref(null);
const personnelLabelList = ref<Array<any>>([]);
const employeeStateList = ref<Array<any>>([]);
const choiceEndDateFrom = ref<string>("");
const choiceEndDateTo = ref<string>("");
const openMore = ref<boolean>(false);
interface FormState {
  pageNum: string | null | number;
  pageSize: string | null | number;
  state: string | null;
  idNum: string | null;
  name: string | null;
  jobNumber: string | null;
  personnelLabel: string | null;
  choiceEndDateFrom: string | null;
  choiceEndDateTo: string | null;
  standardBudgetFrom: string | null;
  standardBudgetTo: string | null;
  budgetBalanceFrom: string | null;
  budgetBalanceTo: string | null;
}
const formRef = ref<FormInstance>();
const formState = reactive<FormState>({
  pageNum: 1,
  pageSize: 10,
  state: null,
  idNum: null,
  name: null,
  jobNumber: null,
  personnelLabel: null,
  choiceEndDateFrom: null,
  choiceEndDateTo: null,
  standardBudgetFrom: null,
  standardBudgetTo: null,
  budgetBalanceFrom: null,
  budgetBalanceTo: null,
});
const columns = ref<Array<any>>([
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "证件号",
    dataIndex: "idNum",
    key: "idNum",
  },
  {
    title: "工号",
    dataIndex: "jobNumber",
    key: "jobNumber",
  },
  {
    title: "人员标签",
    dataIndex: "personnelLabelZh",
    key: "personnelLabelZh",
  },
  {
    title: "生效日期",
    dataIndex: "effectiveDate",
    key: "effectiveDate",
  },
  {
    title: "标准积分",
    dataIndex: "standardBudget",
    key: "standardBudget",
  },
  {
    title: "实际积分",
    dataIndex: "actualBudget",
    key: "actualBudget",
  },
  {
    title: "剩余积分",
    dataIndex: "budgetBalance",
    key: "budgetBalance",
  },
  {
    title: "窗口期",
    dataIndex: "choiceDateRange",
    key: "choiceDateRange",
  },
  {
    title: "状态",
    dataIndex: "stateZh",
    key: "stateZh",
  },
  {
    title: "购买记录",
    dataIndex: "action",
    key: "action",
  },
]);

onMounted(() => {
  getTableData();
  getDic({}).then((res) => {
    personnelLabelList.value = res.data.personnelLabelList;
    employeeStateList.value = res.data.employeeStateList;
  }).catch;
});

const getTableData = async (data: any = {}) => {
  loading.value = true;
  let res: any = await getFlexEmployeeList(data);
  if (res.code == 200) {
    dataSource.value = res.data.dataList;
    totalData.value = res.data.total;
    currentPage.value = res.data.pageNum;
  }

  loading.value = false;
};

const showExportModal = () => {
  if (flexExportRecordModal.value) flexExportRecordModal.value.showModal();
};

const handleExport = debounce(async () => {
  let values = {
    state: formState.state,
    choiceEndDateFrom: choiceEndDateFrom.value,
    choiceEndDateTo: choiceEndDateTo.value,
    standardBudgetTo: formState.standardBudgetTo,
    standardBudgetFrom: formState.standardBudgetFrom,
    budgetBalanceFrom: formState.budgetBalanceFrom,
    budgetBalanceTo: formState.budgetBalanceTo,
  };
  let resExport: any = await exportFlexEmployeeList(values);
  if (resExport.code == 200) {
    message.success("导出成功，请前往导出记录查看下载");
  } else {
    message.error("导出失败");
  }
}, 500);

const handleFinishFailed = (errors: any) => {
  console.log(errors);
};

const showModalByCheckBoughtRecordModal = (record: any) => {
  if (flexBoughtRecordModal.value)
    flexBoughtRecordModal.value.showModal(record);
};

const handleSearch = (values: FormState) => {
  values.choiceEndDateFrom = choiceEndDateFrom.value;
  values.choiceEndDateTo = choiceEndDateTo.value;
  values.standardBudgetTo = formState.standardBudgetTo;
  values.standardBudgetFrom = formState.standardBudgetFrom;
  values.budgetBalanceFrom = formState.budgetBalanceFrom;
  values.budgetBalanceTo = formState.budgetBalanceTo;
  getTableData(values);
};

const onChangeRangePicker = (date: any, dateString: any) => {
  choiceEndDateFrom.value = dateString[0];
  choiceEndDateTo.value = dateString[1];
};
const onChangePage = (pageNum: number, pageSize: number) => {
  let data = formState as FormState;
  data.pageNum = pageNum;
  data.pageSize = pageSize;
  currentPage.value = pageNum;
  pageSizeConfig.value = pageSize;
  getTableData(data);
};
const Reset = () => {
  if (formRef.value) formRef.value.resetFields();
  choiceEndDateFrom.value = "";
  choiceEndDateTo.value = "";
  for (let key in formState) {
    formState[key] = null;
  }
};
</script>

<style lang="scss" scoped>
.button-collection-r {
  float: right;
  .button-c {
    margin-left: 12px;
  }
}
</style>