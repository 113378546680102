<template>
  <a-card style="max-width: 1440px; margin: 0 auto; margin-top: 32px">
    <div>
      <a-form
        ref="formRef"
        :model="formState"
        @finish="handleSearch"
        @finishFailed="handleFinishFailed"
        style="text-align: left"
      >
        <a-row :gutter="24">
          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <a-form-item label="姓名：" name="insuredName">
              <a-input
                v-model:value="formState.insuredName"
                placeholder="请输入姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <a-form-item label="证件号：" name="insuredIdNum">
              <a-input
                v-model:value="formState.insuredIdNum"
                placeholder="请输入证件号"
              />
            </a-form-item>
          </a-col>
          <template v-if="openMore">
            <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
              <a-form-item label="人员类别：" name="relation">
                <a-select
                  ref="select"
                  v-model:value="formState.relation"
                  placeholder="请选择"
                >
                  <a-select-option value>请选择</a-select-option>
                  <a-select-option
                    v-for="foo in relationDicList"
                    :key="foo.code"
                    >{{ foo.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
              <a-form-item label="保全类型：" name="type">
                <a-select
                  ref="select"
                  v-model:value="formState.type"
                  placeholder="请选择"
                >
                  <a-select-option value>请选择</a-select-option>
                  <a-select-option v-for="foo in posTypeList" :key="foo.code">{{
                    foo.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
              <a-form-item label="状态：" name="hrPosState">
                <a-select
                  ref="select"
                  v-model:value="formState.hrPosState"
                  placeholder="请选择"
                >
                  <a-select-option :value="null">请选择</a-select-option>
                  <a-select-option
                    v-for="foo in hrPosStateList"
                    :key="foo.code"
                    >{{ foo.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
              <a-form-item label="工号：" name="employJobNumber">
                <a-input
                  v-model:value="formState.employJobNumber"
                  placeholder="请输入工号"
                />
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
              <a-form-item label="保障方案：" name="planPackageId">
                <a-select
                  ref="select"
                  v-model:value="formState.planPackageId"
                  placeholder="请选择"
                >
                  <a-select-option value>请选择</a-select-option>
                  <a-select-option
                    v-for="foo in planPackageDicList"
                    :key="foo.code"
                    >{{ foo.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </template>
          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="{ float: 'right', overflow: 'hidden' } || {}"
            >
              <a-button
                style="margin-left: 20px; width: 90px"
                type="primary"
                html-type="submit"
                >查询</a-button
              >
              <a-button style="margin-left: 16px; width: 90px" @click="Reset()"
                >重置</a-button
              >
              <a
                @click="
                  () => {
                    openMore = !openMore;
                  }
                "
                style="margin-left: 8px; color: #42b983"
              >
                {{ openMore ? "收起" : "展开" }}
                <DownOutlined v-if="!openMore" />
                <UpOutlined v-else />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="button-collection-c">
      <a-button type="primary" @click="showAddPosModal"
        ><UserAddOutlined />单人增保</a-button
      >
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <router-link :to="{ name: 'posBatchAdd' }">
              <a-menu-item key="1"
                ><UsergroupAddOutlined />&nbsp;&nbsp;批量增人</a-menu-item
              >
            </router-link>
            <router-link :to="{ name: 'posBatchReduce' }">
              <a-menu-item key="2"
                ><UsergroupDeleteOutlined />&nbsp;&nbsp;批量减人</a-menu-item
              >
            </router-link>
          </a-menu>
        </template>
        <a-button class="button-c" type="primary">
          批量操作
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <!-- <a-button class="button-c" type="primary">收集投保信息</a-button> -->
    </div>
    <div class="button-collection-r">
      <a-button type="primary" @click="handleExport">
        <ExportOutlined />导出人员清单</a-button
      >

      <a-button class="button-c" type="default" @click="showExportModal"
        ><BarsOutlined />导出记录</a-button
      >
    </div>
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{
        current: currentPage,
        onChange: onChangePage,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: totalData,
        pageSize: pageSizeConfig,
        showTotal: (totalData) => `共 ${totalData} 条数据`,
      }"
      :loading="loading"
      style="margin-top: 16px"
    >
      <template #bodyCell="{ column, text, record }">
        <span v-if="column.key === 'action'">
          <a
            @click="showModalByCheckPosDetailInfo(record)"
            style="color: #42b983"
            >查看</a
          >
          <a-divider type="vertical" />
          <span v-if="record.type == '1' && record.ifValid != '-1'">
            <a @click="showReducePosModal(record)" style="color: #42b983"
              >减保</a
            >
            <a-divider type="vertical" />
          </span>
          <span v-if="record.type == '1' && record.ifValid == '-1'">
            <a-popconfirm
              title="是否撤回投保？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="revokeAddOrReducePos(record.id)"
            >
              <a style="color: #42b983">撤回投保</a>
            </a-popconfirm>
            <a-divider type="vertical" />
          </span>
          <span v-if="record.type == '0' && record.ifValid == '-1'">
            <a-popconfirm
              title="是否撤回减保？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="revokeAddOrReducePos(record.id)"
            >
              <a style="color: #42b983">撤回减保</a>
            </a-popconfirm>

            <a-divider type="vertical" />
          </span>
          <span v-if="record.type == '1' && record.ifValid != '-1'">
            <a @click="showChangePlanPackage(record)" style="color: #42b983"
              >变更层级</a
            >
          </span>
        </span>
      </template>
    </a-table>
  </a-card>
  <check-pos-detail-info
    ref="checkPosDetailInfo"
    :bankDicList="bankDicList"
    :planPackageDicList="planPackageDicList"
    :idTypeDicList="idTypeDicList"
    :genderDicList="genderDicList"
    :branchDicList="branchDicList"
    :relationDicList="relationDicList"
  />
  <add-pos-modal
    ref="addPosModal"
    :bankDicList="bankDicList"
    :planPackageDicList="planPackageDicList"
    :idTypeDicList="idTypeDicList"
    :genderDicList="genderDicList"
    :branchDicList="branchDicList"
    :relationDicList="relationDicList"
    :simple="personalList"
    @refresh="getTableData"
  />
  <reduce-pos-modal
    ref="reducePosModal"
    :bankDicList="bankDicList"
    :planPackageDicList="planPackageDicList"
    :idTypeDicList="idTypeDicList"
    :genderDicList="genderDicList"
    :branchDicList="branchDicList"
    :relationDicList="relationDicList"
    @refresh="getTableData"
  />
  <pos-export-record-modal ref="posExportRecordModal" />
  <change-plan-package
    ref="changePlanPackage"
    :bankDicList="bankDicList"
    :planPackageDicList="planPackageDicList"
    :idTypeDicList="idTypeDicList"
    :genderDicList="genderDicList"
    :branchDicList="branchDicList"
    :relationDicList="relationDicList"
    @refresh="getTableData"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted, reactive } from "vue";
import { FormInstance, message } from "ant-design-vue";
import {
  DownOutlined,
  UpOutlined,
  ExportOutlined,
  BarsOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons-vue";
import { getPosList, getDic, revokePos, posExport } from "@/api/pos";
import { getBasicInfo } from "@/api/home";
import CheckPosDetailInfo from "./modules/CheckPosDetailInfo.vue";
import AddPosModal from "./modules/AddPosModal.vue";
import ReducePosModal from "./modules/ReducePosModal.vue";
import ChangePlanPackage from "./modules/ChangePlanPackage.vue";
import PosExportRecordModal from "./modules/PosExportRecordModal.vue";
import { useRoute } from "vue-router";
import store from "@/store";
import { debounce } from "@/utils/tools";
const route = useRoute();
const checkPosDetailInfo = ref(null);
const currentPage = ref(1);
const pageSizeConfig = ref(10);
const loading = ref<boolean>(false);
const totalData = ref(0);
const addPosModal = ref(null);
const reducePosModal = ref(null);
const posExportRecordModal = ref(null);
const changePlanPackage = ref(null);
const personalList = ref<Array<any>>([]);
interface FormState {
  pageNum: null | number;
  pageSize: null | number;
  insuredName: string;
  employJobNumber: string;
  insuredIdNum: string;
  planPackageId: string | undefined;
  relation: string | undefined;
  type: string | undefined;
  hrPosState: string | undefined;
}
const formRef = ref<FormInstance>();
const formState = reactive<FormState>({
  pageNum: 1,
  pageSize: 10,
  insuredName: "",
  employJobNumber: "",
  insuredIdNum: "",
  planPackageId: undefined,
  relation: undefined,
  type: undefined,
  hrPosState: undefined,
});
const openMore = ref<boolean>(false);
const columns = ref<Array<any>>([
  {
    title: "姓名",
    dataIndex: "insuredName",
    key: "insuredName",
    align: "left",
    width: "6%",
    ellipsis: true,
  },
  {
    title: "证件类型",
    dataIndex: "insuredIdTypeZh",
    key: "insuredIdTypeZh",
    align: "left",
    width: "7%",
    ellipsis: true,
  },
  {
    title: "证件号",
    dataIndex: "insuredIdNum",
    key: "insuredIdNum",
    align: "left",
    width: "13%",
    ellipsis: true,
  },
  {
    title: "出生日期",
    dataIndex: "insuredBirth",
    key: "insuredBirth",
    align: "left",
    width: "9%",
  },
  {
    title: "性别",
    dataIndex: "insuredGenderZh",
    key: "insuredGenderZh",
    align: "left",
    width: "5%",
  },
  {
    title: "人员类别",
    dataIndex: "relationZh",
    key: "relationZh",
    align: "left",
    width: "5%",
  },
  {
    title: "员工",
    dataIndex: "employeeName",
    key: "employeeName",
    align: "left",
    width: "6%",
    ellipsis: true,
  },
  {
    title: "保障方案",
    dataIndex: "planPackageName",
    key: "planPackageName",
    align: "left",
    width: "7%",
    ellipsis: true,
  },
  {
    title: "有效期",
    dataIndex: "startToEndTime",
    key: "startToEndTime",
    align: "left",
    ellipsis: true,
  },
  {
    title: "保全类型",
    dataIndex: "typeZh",
    key: "typeZh",
    align: "left",
    width: "5%",
  },
  {
    title: "状态",
    dataIndex: "ifValidZh",
    key: "ifValidZh",
    align: "left",
    width: "6%",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    width: "13%",
    align: "left",
  },
]);
const dataSource = ref<Array<any>>([]);
const relationDicList = ref<Array<any>>([]);
const planPackageDicList = ref<Array<any>>([]);
const idTypeDicList = ref<Array<any>>([]);
const genderDicList = ref<Array<any>>([]);
const branchDicList = ref<Array<any>>([]);
const bankDicList = ref<Array<any>>([]);
const hrPosStateList = ref<Array<any>>([]);
const posTypeList = ref<Array<any>>([]);

onMounted(() => {
  let isShowAddPosModal = store.getters.GET_IS_SHOW_ADD_POS_MODAL;
  if (isShowAddPosModal) {
    store.dispatch("setIsShowAddPosModal", false);
    showAddPosModal();
  }
  getTableData({});
  getDic({}).then((res) => {
    relationDicList.value = res.data.relationDicList;
    planPackageDicList.value = res.data.planPackageDicList;
    idTypeDicList.value = res.data.idTypeDicList;
    genderDicList.value = res.data.genderDicList;
    branchDicList.value = res.data.branchDicList;
    bankDicList.value = res.data.bankDicList;
    hrPosStateList.value = res.data.hrPosStateList;
    posTypeList.value = res.data.posTypeList;
  }).catch;
});

const handleExport = debounce(async (values: FormState) => {
  let resExport: any = await posExport(formState);
  if (resExport.code == 200) {
    message.success("导出成功，请前往导出记录查看下载");
  } else {
    message.error("导出失败");
  }
}, 500);

const handleSearch = (values: FormState) => {
  getTableData(values);
};
const onChangePage = (pageNum: number, pageSize: number) => {
  let data = formState as FormState;
  data.pageNum = pageNum;
  data.pageSize = pageSize;
  pageSizeConfig.value = pageSize;
  getTableData(data);
};
const handleFinishFailed = (errors: any) => {
  console.log(errors);
};
const showModalByCheckPosDetailInfo = (record: any) => {
  if (checkPosDetailInfo.value) checkPosDetailInfo.value.showModal(record);
};
const showAddPosModal = () => {
  if (addPosModal.value) {
    getBasicInfo().then((r) => {
      personalList.value = [];
      r.data.contractBriefInfo.personalList.forEach((r) => {
        if (r == "WORK_PLACE") {
          personalList.value.push({
            workPlace: "工作地",
          });
        } else if (r == "AFFILIATION") {
          personalList.value.push({
            affiliation: "三级机构",
          });
        } else if (r == "BANK_BRANCH") {
          personalList.value.push({
            bankBranch: "备注",
          });
        } else if (r == "WAGE") {
          personalList.value.push({
            wage: "工资/社保基数",
          });
        } else if (r == "ACCIDENT") {
          personalList.value.push({
            accident: "月薪倍数意外保额",
          });
        } else if (r == "OCC_CATE") {
          personalList.value.push({
            occCate: "职业类别",
          });
        } else if (r == "DEATH") {
          personalList.value.push({
            death: "月薪倍数定寿保额",
          });
        } else if (r == "CUSTOM_ACCIDENT") {
          personalList.value.push({
            customAccident: "自定义意外保额",
          });
        } else if (r == "CUSTOM_DEATH") {
          personalList.value.push({
            customDeath: "自定义定寿保额",
          });
        } else if (r == "CUSTOM_SERIOUS_ILLNESS") {
          personalList.value.push({
            customSeriousIllness: "自定义重疾保额",
          });
        }
      });
    });
    addPosModal.value.showModal();
  }
};
const showExportModal = () => {
  if (posExportRecordModal.value) posExportRecordModal.value.showModal();
};
const showReducePosModal = (record: any) => {
  if (reducePosModal.value) reducePosModal.value.showModal(record);
};
const showChangePlanPackage = (record: any) => {
  if (changePlanPackage.value) changePlanPackage.value.showModal(record);
};
const revokeAddOrReducePos = (id: number) => {
  let params = {
    hrBatchInfoId: id,
  };
  revokePos(params)
    .then((res: any) => {
      if (res.code == 200) {
        getTableData();
      } else {
        message.error(res.msg);
      }
    })
    .catch();
};
const Reset = () => {
  if (formRef.value) formRef.value.resetFields();
};
const getTableData = (data: any = {}) => {
  loading.value = true;
  getPosList(data)
    .then((res) => {
      dataSource.value = res.data.dataList;
      totalData.value = res.data.total;
      currentPage.value = res.data.pageNum;
      loading.value = false;
    })
    .catch();
};
</script>

<style lang="scss" scoped>
.button-collection-c {
  float: left;
  .button-c {
    margin-left: 12px;
  }
}
.button-collection-r {
  float: right;
  .button-c {
    margin-left: 12px;
  }
}
</style>