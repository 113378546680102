import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { GlobalHeader } from '@/components/Header'
import HomeView from '@/views/HomeView.vue'
import AntdvTestView from '@/views/AntdvTestView.vue'
import DataStatistics from '@/views/DataStatistics.vue'
import ClaimInquiry from '@/views/ClaimInquiry.vue'
import OperationLog from '@/views/OperationLog.vue'
import PosBatchAdd from '@/views/PosBatchAdd.vue'
import PosBatchReduce from '@/views/PosBatchReduce.vue'
import PosList from '@/views/PosList.vue'
import Questionnaire from '@/views/Questionnaire.vue'
import QuestionnaireInvalidated from '@/views/QuestionnaireInvalidated.vue'
import ClaimDataAnalyse from '@/views/dataStatisticsViews/ClaimDataAnalyse.vue'
import FlexDataBenchmark from '@/views/dataStatisticsViews/FlexDataBenchmark.vue'
import FlexDataDashboard from '@/views/dataStatisticsViews/FlexDataDashboard.vue'
import MedicalMap from '@/views/dataStatisticsViews/MedicalMap.vue'
import PolicyPerspective from '@/views/dataStatisticsViews/PolicyPerspective.vue'
import store from '@/store'
import { childrenRoutesConfig, routerConfig, defaultRoutesKeys } from '@/router/config'
import { getPermission } from '@/api/login'
import { ACCESS_TOKEN } from '@/config/config'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: GlobalHeader,
    redirect: '/login',
    children: [
      // {
      //   path: '/home',
      //   name: 'home',
      //   component: HomeView,
      // },
      // {
      //   path: '/dataStatistics',
      //   name: 'dataStatistics',
      //   children: [
      //     {
      //       path: '/claimDataAnalyse',
      //       name: 'claimDataAnalyse',
      //       component: ClaimDataAnalyse,
      //     },
      //     {
      //       path: '/flexDataBenchmark',
      //       name: 'flexDataBenchmark',
      //       component: FlexDataBenchmark,
      //     },
      //     {
      //       path: '/flexDataDashboard',
      //       name: 'flexDataDashboard',
      //       component: FlexDataDashboard,
      //     },
      //     {
      //       path: '/medicalMap',
      //       name: 'medicalMap',
      //       component: MedicalMap,
      //     },
      //     {
      //       path: '/policyPerspective',
      //       name: 'policyPerspective',
      //       component: PolicyPerspective,
      //     },
      //   ]
      // },
      // {
      //   path: '/claimInquiry',
      //   name: 'claimInquiry',
      //   component: ClaimInquiry,
      // },
      // {
      //   path: '/operationLog',
      //   name: 'operationLog',
      //   component: OperationLog,
      // },

      // {
      //   path: '/posList',
      //   name: 'posList',
      //   redirect: '/posList',
      //   children: [
      //     {
      //       path: '/posList',
      //       name: 'posList',
      //       component: PosList,
      //     },
      //     {
      //       path: '/posList/posBatchAdd',
      //       name: 'posBatchAdd',
      //       component: PosBatchAdd,
      //     },
      //     {
      //       path: '/posList/posBatchReduce',
      //       name: 'posBatchReduce',
      //       component: PosBatchReduce,
      //     },
      //   ]
      // }
    ],
  },

  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: Questionnaire,
  },
  {
    path: '/questionnaireInvalidated',
    name: 'questionnaireInvalidated',
    component: QuestionnaireInvalidated,
  },
  {
    path: '/antdv-test',
    name: 'test',
    component: AntdvTestView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// if (localStorage.getItem(ACCESS_TOKEN)) {
//   getPermission().then((res) => {

//   }).catch()
// }


export default router
