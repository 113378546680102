import { ACCESS_TOKEN } from "./config/config";
import router from "./router";
import store from "./store";
import { generateRoutes } from '@/utils/router'
import { notification } from "ant-design-vue";
import { getPermission } from '@/api/login'
import { arrayType } from "ant-design-vue/es/_util/type";
const whiteList: any = ['login', 'questionnaire', 'questionnaireInvalidated'];

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const routerList: Array<String> = Array.from(store.getters.GET_ROUTERLIST);
  if (token) {
    if (routerList.length != 0 && to.name) {
      if (routerList.includes(String(to.name)) || to.path == '/' || whiteList.includes(to.name)) {
        next()
      } else if (routerList.length != 0 && to.name == 'home') {
        next({ name: routerList[0].toString() })
      }
      else {
        notification['error']({
          message: '权限异常',
          description:
            '请联系客户经理，权限受限',
        });
      }
    } else {
      getPermission().then((res) => {
        if (res.data.length > 0) {
          generateRoutes(res).then(() => {
            next()
          }).catch()
        } else {
          notification['error']({
            message: '账号异常',
            description:
              '请联系客户经理，账号异常',
          });
          store.dispatch("logOut");
        }

      }).catch()

    }

  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      store.dispatch('logOut');
    }
  }
})
