import { axios } from '@/utils/request'
import api from './index'

export function getVerifyCode(parameter: object) {
  return axios({
    url: api.getVerifyCode,
    method: 'post',
    data: parameter
  })
}

export function loginApi(parameter: object) {
  return axios({
    url: api.login,
    method: 'post',
    data: parameter
  })
}

export function getPermission() {
  return axios({
    url: api.getPermission,
    method: 'post',
  })
}

export function changePassword(parameter: object) {
  return axios({
    url: api.changePassword,
    method: 'post',
    data: parameter
  })
}

export function getUserInfo(parameter: object) {
  return axios({
    url: api.getUserInfo,
    method: 'post',
    data: parameter
  })
}

export function handleChangeUser(parameter: object) {
  return axios({
    url: api.handleChangeUser,
    method: 'post',
    data: parameter
  })
}

