import { RouteRecordRaw } from 'vue-router'
import { GlobalHeader } from '@/components/Header'
import store from '@/store';
import { routesConfig } from '@/router/config'
import router from '@/router';
import { dataStatisticsChildrenRoutes } from '@/config/config'
import { notification } from "ant-design-vue";
import { childrenRoutesConfig, routerConfig, defaultRoutesKeys } from '@/router/config'

export function generateRoutes(res: any) {
  return new Promise((resolve, reject) => {

    if (res.data.length > 0) {
      let childrenRoutes: any = []
      res.data.forEach((e: any) => {
        if (defaultRoutesKeys.includes(e.code)) {
          childrenRoutes.push(routerConfig.get(e.code) as RouteRecordRaw)
        }
      })
      router.addRoute({ path: '/', redirect: 'login', component: GlobalHeader, children: childrenRoutes })

      router.push({ path: '/' })
    } else {
      notification['error']({
        message: '账号异常',
        description:
          '请联系客户经理，账号异常2',
      });
    }


    const routes: Array<RouteRecordRaw> = [
      {
        path: '/',
        component: GlobalHeader,
        redirect: '/home',
        children: [],
      },
    ]
    const routerList: Array<String> = []
    const AHashTable = createHashTable(res.data)
    const BHashTable = createHashTable(routesConfig)
    const CHashTable = createHashTable(childrenRoutesConfig)
    res.data.map((AItem: any) => {
      let routeElement = findItemInB(AItem, BHashTable)
      if (routeElement) {
        routes ? routes.push(routeElement) : ''
      } else {
        routeElement = findItemInC(AItem, CHashTable)
        if (routeElement) {
          routes.map((RItem: any) => {
            if (RItem.name == "dataStatistics") {
              RItem.children.push(routeElement)
            }
          })
        }
      }
      routerList.push(AItem.name)
      if (AItem.name == 'posList') {
        routerList.push('posBatchAdd')
        routerList.push('posBatchReduce')
      }
    })
    store.dispatch('generateRouterList', routerList)
    store.dispatch('generateRoutes', routes)
    resolve(true);
  })

}

function createHashTable(array: any[]) {
  const hashTable: any = {}
  array.map((item) => {
    const key = item['name']
    if (hashTable[key]) {
      throw new Error(`Duplicate key ${key}`)
    }
    hashTable[key] = item
  })
  return hashTable;
}

function findItemInB(AItem: any, BHashTable: any): any | undefined {
  const key = AItem['name']
  return BHashTable[key]
}

function findItemInC(AItem: any, CHashTable: any): any | undefined {
  const key = AItem['name']
  return CHashTable[key]
}