export function echartsXAxis(data: any) {
  var xAxis: Array<string> = []
  data.map((e: any) => {
    let value = e.month + '月'
    xAxis.push(value)
  })
  return xAxis
}

export function echartsYAxis(data: any) {
  var yAxis: Array<string> = []
  data.map((e: any) => {
    let value = e.value
    yAxis.push(value)
  })
  return yAxis
}

export function echartsYAxisByPosAddAndReduce(list1: any, list2: any) {
  var yAxis: Array<string> = []
  let list1YearMonth = xAxisTool(list1);
  let list2YearMonth = xAxisTool(list2);
  let newArray = unionArrays(list1YearMonth, list2YearMonth)
  newArray.forEach((e: any) => {
    const foundItem1 = list1.find((l1: any) => e == l1.year * 100 + l1.month);
    if (foundItem1) {
      yAxis.push(foundItem1.value)
    } else {
      yAxis.push('0')
    }
  })
  return yAxis
}


export function echartsXAxisByPosAddAndReduce(list1: any, list2: any) {
  var xAxis: Array<string> = []
  let list1YearMonth = xAxisTool(list1);
  let list2YearMonth = xAxisTool(list2);
  let newArray = unionArrays(list1YearMonth, list2YearMonth)
  newArray.forEach((e: any) => {
    const foundItem1 = list1.find((l1: any) => e == l1.year * 100 + l1.month);
    if (foundItem1) {
      xAxis.push(foundItem1.month + '月')
    } else {
      const foundItem2 = list2.find((l2: any) => e == l2.year * 100 + l2.month);
      if (foundItem2) {
        xAxis.push(foundItem2.month + '月')
      }
    }
  })
  return xAxis
}
//拼新值 
function xAxisTool(array: any) {
  let arr: any = []
  array.forEach((e: any) => {
    arr.push(e.year * 100 + e.month)
  })
  return arr
}
//拼新数组取并集
function unionArrays<T>(array1: T[], array2: T[]): T[] {
  return [...new Set([...array1, ...array2])];
}