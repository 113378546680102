import { RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AntdvTestView from '@/views/AntdvTestView.vue'
import DataStatistics from '@/views/DataStatistics.vue'
import ClaimInquiry from '@/views/ClaimInquiry.vue'
import FlexDataCheck from '@/views/FlexDataCheck.vue'
import OperationLog from '@/views/OperationLog.vue'
import PosBatchAdd from '@/views/PosBatchAdd.vue'
import PosBatchReduce from '@/views/PosBatchReduce.vue'
import PosList from '@/views/PosList.vue'
import Questionnaire from '@/views/Questionnaire.vue'
import QuestionnaireInvalidated from '@/views/QuestionnaireInvalidated.vue'
import ClaimDataAnalyse from '@/views/dataStatisticsViews/ClaimDataAnalyse.vue'
import FlexDataBenchmark from '@/views/dataStatisticsViews/FlexDataBenchmark.vue'
import FlexDataDashboard from '@/views/dataStatisticsViews/FlexDataDashboard.vue'
import MedicalMap from '@/views/dataStatisticsViews/MedicalMap.vue'
import PolicyPerspective from '@/views/dataStatisticsViews/PolicyPerspective.vue'

//只是控制显示的菜单列表
export const routesConfig: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/dataStatistics',
    name: 'dataStatistics',
    component: DataStatistics,
    children: []
  },
  {
    path: '/claimInquiry',
    name: 'claimInquiry',
    component: ClaimInquiry,
  },
  {
    path: '/flexDataCheck',
    name: 'flexDataCheck',
    component: FlexDataCheck
  },
  {
    path: '/operationLog',
    name: 'operationLog',
    component: OperationLog,
  },

  {
    path: '/posList',
    name: 'posList',
    component: PosList,
    // children: [
    //   {
    //     path: '/posList',
    //     name: 'posList',
    //     component: PosList,
    //   },
    //   {
    //     path: '/posBatchAdd',
    //     name: 'posBatchAdd',
    //     component: PosBatchAdd,
    //   },
    //   {
    //     path: '/posBatchReduce',
    //     name: 'posBatchReduce',
    //     component: PosBatchReduce,
    //   },
    // ]
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: Questionnaire,
  },
  {
    path: '/questionnaireInvalidated',
    name: 'questionnaireInvalidated',
    component: QuestionnaireInvalidated,
  },
  {
    path: '/antdv-test',
    name: 'test',
    component: AntdvTestView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

export const childrenRoutesConfig = [
  {
    path: '/claimDataAnalyse',
    name: 'claimDataAnalyse',
    component: ClaimDataAnalyse,
  },
  {
    path: '/flexDataBenchmark',
    name: 'flexDataBenchmark',
    component: FlexDataBenchmark,
  },
  {
    path: '/flexDataDashboard',
    name: 'flexDataDashboard',
    component: FlexDataDashboard,
  },
  {
    path: '/medicalMap',
    name: 'medicalMap',
    component: MedicalMap,
  },
  {
    path: '/policyPerspective',
    name: 'policyPerspective',
    component: PolicyPerspective,
  },

]

export const routerConfig: Map<String, RouteRecordRaw> = new Map();
routerConfig.set('home', {
  path: '/home',
  name: 'home',
  component: HomeView,
})
routerConfig.set('posList', {
  path: '/posList',
  name: 'posList',
  redirect: '/posList',
  children: [
    {
      path: '/posList',
      name: 'posList',
      component: PosList,
    },
    {
      path: '/posList/posBatchAdd',
      name: 'posBatchAdd',
      component: PosBatchAdd,
    },
    {
      path: '/posList/posBatchReduce',
      name: 'posBatchReduce',
      component: PosBatchReduce,
    },
  ]
})
routerConfig.set('claimInquiry', {
  path: '/claimInquiry',
  name: 'claimInquiry',
  component: ClaimInquiry,
})

routerConfig.set('flexDataCheck', {
  path: '/flexDataCheck',
  name: 'flexDataCheck',
  component: FlexDataCheck
})
routerConfig.set('dataStatistics', {
  path: '/dataStatistics',
  name: 'dataStatistics',
  children: [
    {
      path: '/claimDataAnalyse',
      name: 'claimDataAnalyse',
      component: ClaimDataAnalyse,
    },
    {
      path: '/flexDataBenchmark',
      name: 'flexDataBenchmark',
      component: FlexDataBenchmark,
    },
    {
      path: '/flexDataDashboard',
      name: 'flexDataDashboard',
      component: FlexDataDashboard,
    },
    {
      path: '/medicalMap',
      name: 'medicalMap',
      component: MedicalMap,
    },
    {
      path: '/policyPerspective',
      name: 'policyPerspective',
      component: PolicyPerspective,
    },

  ]
})
routerConfig.set('operationLog', {
  path: '/operationLog',
  name: 'operationLog',
  component: OperationLog,
})


//路由加
export const defaultRoutesKeys = [
  'home',
  'posList',
  'claimInquiry',
  'dataStatistics',
  'operationLog',
  'flexDataCheck'
]
