<template>
  <a-card
    style="
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 32px;
      text-align: left;
    "
    title="批量增人"
  >
    <div class="title-box">
      <div style="margin-bottom: 16px">
        <a-row class="row-c">
          <div class="row-content">1、下载批量增人模板，批量填写信息</div>
          <a-button
            style="margin-left: 16px"
            type="primary"
            @click="handleDownloadTemplate"
            ><DownloadOutlined />下载批量增人模板</a-button
          >
        </a-row>
      </div>
      <div style="margin-bottom: 16px">
        <a-row class="row-c">
          <div class="row-content">2、上传填写好的信息表</div>
          <a-upload
            v-model:file-list="fileList"
            name="file"
            :headers="uploadRequestHeaders"
            :action="uploadUrl"
            @change="handleUpload"
            :maxCount="1"
            :data="{ type: 'ADD' }"
          >
            <a-button style="margin-left: 16px" type="primary">
              <upload-outlined></upload-outlined>选择文件
            </a-button>
          </a-upload>
        </a-row>
      </div>
      <div style="margin-bottom: 16px">
        <a-row class="row-c">
          <div class="row-content">3、文件选择无误后提交增人</div>
          <a-button style="margin-left: 16px" type="primary" @click="importFile"
            >提交增人</a-button
          >
        </a-row>
      </div>
    </div>
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{
        current: currentPage,
        onChange: onChangePage,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: totalData,
        pageSize: pageSizeConfig,
      }"
      :loading="loading"
    >
      <template #bodyCell="{ column, text, record }">
        <span v-if="column.key === 'name'">
          <a
            v-if="
              record.operationState == 'IMPORTED_ERROR' ||
              record.operationState == 'VERIFY_ERROR'
            "
            style="color: red"
            @click="getDownloadUrl(record)"
            >{{ record.name }}</a
          >
          <a
            v-if="
              record.operationState != 'IMPORTED_ERROR' &&
              record.operationState != 'VERIFY_ERROR'
            "
            style="color: #42b983"
            @click="getDownloadUrl(record)"
            >{{ record.name }}</a
          >
        </span>
        <span v-if="column.key === 'operationStateZh'">
          <span v-if="record.operationState == 'VERIFY_ERROR'"
            >{{ record.operationStateZh }}（请下载文件查看错误）</span
          >

          <span v-else>{{ record.operationStateZh }}</span>
        </span>
      </template>
    </a-table>
  </a-card>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { UploadOutlined } from "@ant-design/icons-vue";
import { message, UploadChangeParam } from "ant-design-vue";
import { DownloadOutlined } from "@ant-design/icons-vue";
import api from "@/api/index";
import { ACCESS_TOKEN } from "@/config/config";
import {
  downLoadTemplate,
  importExcelData,
  getAttachmentList,
  downloadFileByPos,
} from "@/api/pos";
import { anyType } from "ant-design-vue/es/_util/type";
const dataSource = ref<Array<any>>([]);
const loading = ref<boolean>(false);
const route = useRoute();
const checkPosDetailInfo = ref(null);
const currentPage = ref(1);
const pageSizeConfig = ref(10);
const totalData = ref(0);
const attachmentId = ref(0);
const fileList = ref([]);
const uploadRequestHeaders = ref({
  "HrPortal-Access-Token": `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  Accept: "application/json",
});
const uploadUrl = api.uploadForPosBatch;
const columns = ref<Array<any>>([
  {
    title: "文件名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "操作人",
    dataIndex: "petName",
    key: "petName",
  },
  {
    title: "操作时间",
    dataIndex: "createdAtTime",
    key: "createdAtTime",
  },
  {
    title: "状态",
    dataIndex: "operationStateZh",
    key: "operationStateZh",
  },
]);
onMounted(() => {
  getTableData();
});
const getTableData = async (paramater: any = {}) => {
  paramater.typeList = ["ADD"];
  let res: any = await getAttachmentList(paramater);

  if (res.code == 200) {
    dataSource.value = res.data.dataList;
    totalData.value = res.data.total;
    checkFileState(res.data.dataList);
  }
};
const handleUpload = (info: UploadChangeParam) => {
  if (info.file.status !== "uploading") {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === "done") {
    message.success(`${info.file.name} file uploaded successfully`);
    if (
      info.fileList[0].response.code == 200 &&
      info.fileList[0].response.data.id
    ) {
      attachmentId.value = info.fileList[0].response.data.id;
    }
  } else if (info.file.status === "error") {
    message.error(`${info.file.name} file upload failed.`);
  }
};
const handleDownloadTemplate = async () => {
  let res: any = await downLoadTemplate({ type: "ADD" });
  let a = document.createElement("a");
  a.href = res.data;
  a.click();
};
const onChangePage = (pageNum: number, pageSize: number) => {
  let data = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  pageSizeConfig.value = pageSize;
  currentPage.value = pageNum;
  getTableData(data);
};

const importFile = async () => {
  if (attachmentId.value == 0) {
    message.error("请上传文件再提交");
  } else {
    let res: any = await importExcelData({ fileId: attachmentId.value });
    if (res.code == 200) {
      message.success("导入成功");
      fileList.value = [];
      attachmentId.value = 0;
      getTableData();
    } else {
      message.error(res.msg);
    }
  }
};
const getDownloadUrl = async (record: any) => {
  let paramater = {
    id: record.id,
    duration: 600,
  };
  let res: any = await downloadFileByPos(paramater);
  if (res.code == 200) {
    let a = document.createElement("a");
    a.href = res.data;
    a.click();
    message.success("下载成功");
  } else {
    message.error(res.msg);
  }
};
const checkFileState = (list: Array<any>) => {
  list.forEach((e) => {
    if (e.operationState == "IMPORTING") {
      setTimeout(() => {
        getTableData();
      }, 3000);
    }
  });
};
</script>

<style lang="scss" scoped>
.title-box {
  padding: 8px 0 24px 0;
  .row-c {
    display: flex;
    align-items: center;
    .row-content {
      margin-left: 16px;
    }
  }
}
</style>