import { axios } from '@/utils/request'
import api from './index'

export function getPosList(parameter: object) {
  return axios({
    url: api.getPosList,
    method: 'post',
    data: parameter
  })
}


export function getDic(parameter: object) {
  return axios({
    url: api.getDic,
    method: 'post',
    data: parameter
  })
}

export function addPos(parameter: object) {
  return axios({
    url: api.addPos,
    method: 'post',
    data: parameter
  })
}


export function updatePos(parameter: object) {
  return axios({
    url: api.updatePos,
    method: 'post',
    data: parameter
  })
}

export function reducePos(parameter: object) {
  return axios({
    url: api.reducePos,
    method: 'post',
    data: parameter
  })
}

export function revokePos(parameter: object) {
  return axios({
    url: api.revokePos,
    method: 'post',
    data: parameter
  })
}

export function changePlan(parameter: object) {
  return axios({
    url: api.changePlan,
    method: 'post',
    data: parameter
  })
}
export function downLoadTemplate(parameter: object) {
  return axios({
    url: api.downLoadTemplate,
    method: 'get',
    params: parameter,
  })
}



export function uploadForPosBatch(parameter: object) {
  return axios({
    url: api.uploadForPosBatch,
    method: 'get',
    params: parameter,
  })
}


export function importExcelData(parameter: object) {
  return axios({
    url: api.importExcelData,
    method: 'post',
    data: parameter,
  })
}

export function exportRecordList(parameter: object) {
  return axios({
    url: api.exportRecordList,
    method: 'post',
    data: parameter,
  })
}

export function posExport(parameter: object) {
  return axios({
    url: api.posExport,
    method: 'post',
    data: parameter,
  })
}


export function getAttachmentList(parameter: object) {
  return axios({
    url: api.getAttachmentList,
    method: 'post',
    data: parameter,
  })
}

export function downloadFileByPos(parameter: object) {
  return axios({
    url: api.downloadFileByPos,
    method: 'post',
    data: parameter,
  })
}