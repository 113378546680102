<template>
  <div>
    <div>ttt</div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
</style>