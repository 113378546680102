import { axios } from '@/utils/request'
import api from './index'

export function claimInquiryList(parameter: object) {
  return axios({
    url: api.claimInquiryList,
    method: 'post',
    data: parameter
  })
}



export function claimExport(parameter: object) {
  return axios({
    url: api.claimExport,
    method: 'post',
    data: parameter
  })
}
