<template>
  <a-card style="max-width: 1440px; margin: 0 auto; margin-top: 32px">
    <div>
      <a-form
        ref="formRef"
        :model="formState"
        @finish="handleSearch"
        @finishFailed="handleFinishFailed"
        style="text-align: left"
      >
        <a-row :gutter="24">
          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <a-form-item label="操作类型：" name="operationCode">
              <a-select
                v-model:value="formState.operationCode"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="foo in operationCodeDicList"
                  :key="foo.code"
                  >{{ foo.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <a-form-item label="操作时间段：" name="operationDateFrom">
              <a-range-picker
                v-model:value="formState.operationDateFrom"
                :placeholder="['开始日期', '结束日期']"
                @change="onChangeRangePicker"
              />
            </a-form-item>
          </a-col>

          <a-col :xxl="6" :xl="8" :lg="8" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="{ float: 'right', overflow: 'hidden' } || {}"
            >
              <a-button
                style="margin-left: 20px; width: 90px"
                type="primary"
                html-type="submit"
                >查询</a-button
              >
              <a-button style="margin-left: 16px; width: 90px" @click="Reset()"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{
        current: currentPage,
        onChange: onChangePage,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: totalData,
        pageSize: pageSizeConfig,
      }"
      :loading="loading"
    >
      <template #bodyCell="{ column, text, record }">
        <span v-if="column.key === 'operationDesc'">
          <span v-if="record.operationCode == 'EXPORT_POS'">
            导出了
            <a @click="getDownloadUrl(record)" style="color: #42b983">{{
              record.attachmentName
            }}</a>
          </span>
          <span v-if="record.operationCode == 'EXPORT_CLAIM'">
            导出了
            <a @click="getDownloadUrl(record)" style="color: #42b983">{{
              record.attachmentName
            }}</a>
          </span>
          <span v-if="record.operationCode == 'POS_REDUCE'"
            >对{{ record.targetUserNames }}进行单人退保操作</span
          >
          <span v-if="record.operationCode == 'POS_ADD'"
            >对{{ record.targetUserNames }}进行单人投保操作</span
          >
          <span v-if="record.operationCode == 'CHANGE_POS_LEVEL'"
            >对{{ record.targetUserNames }}进行了层级变更</span
          >
          <span v-if="record.operationCode == 'UPLOAD_POS_BATCH_ADD'">
            上传了批量投保文件
            <a @click="getDownloadUrl(record)" style="color: #42b983">{{
              record.attachmentName
            }}</a>
          </span>
          <span v-if="record.operationCode == 'UPLOAD_POS_BATCH_REDUCE'">
            上传了批量退保文件
            <a @click="getDownloadUrl(record)" style="color: #42b983">{{
              record.attachmentName
            }}</a>
          </span>
        </span>
      </template>
    </a-table>
  </a-card>
</template>

<script lang="ts" setup>
import { ref, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import api from "@/api/index";
import { downloadFileByPos, getDic } from "@/api/pos";
import { FormInstance, message } from "ant-design-vue";
import { getOperationLog } from "@/api/operation";
const dataSource = ref<Array<any>>([]);
const loading = ref<boolean>(false);
const route = useRoute();
const checkPosDetailInfo = ref(null);
const currentPage = ref(1);
const pageSizeConfig = ref(10);
const totalData = ref(0);
const operationDateFrom = ref<string>("");
const operationDateTo = ref<string>("");
const operationCodeDicList = ref<Array<any>>([]);
interface FormState {
  state: string | undefined;
  liabilityType: string | undefined;
  operationDateFrom: string | undefined;
  operationDateTo: string | undefined;
}
const formRef = ref<FormInstance>();
const formState = reactive<FormState>({
  state: undefined,
  liabilityType: undefined,
  operationDateFrom: undefined,
  operationDateTo: undefined,
});
const columns = ref<Array<any>>([
  {
    title: "时间",
    dataIndex: "operationTime",
    key: "operationTime",
  },
  {
    title: "账号",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "操作类型",
    dataIndex: "operationName",
    key: "operationName",
  },
  {
    title: "详细内容",
    dataIndex: "operationDesc",
    key: "operationDesc",
  },
]);

onMounted(() => {
  getTableData();
  getDic({}).then((res) => {
    operationCodeDicList.value = res.data.operationCodeDicList;
  }).catch;
});

const onChangePage = (pageNum: number, pageSize: number) => {
  let data = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  currentPage.value = pageNum;
  pageSizeConfig.value = pageSize;
  getTableData(data);
};
const getTableData = async (paramater: any = {}) => {
  let res: any = await getOperationLog(paramater);
  if (res.code == 200) {
    dataSource.value = res.data.dataList;
    totalData.value = res.data.total;
  }
};
const onChangeRangePicker = (date: any, dateString: any) => {
  operationDateFrom.value = dateString[0];
  operationDateTo.value = dateString[1];
};
const getDownloadUrl = async (record: any) => {
  let paramater = {
    id: record.attachmentId,
    duration: 600,
  };
  let res: any = await downloadFileByPos(paramater);
  if (res.code == 200) {
    let a = document.createElement("a");
    a.href = res.data;
    a.click();
  } else {
    message.error(res.msg);
  }
};
const Reset = () => {
  if (formRef.value) formRef.value.resetFields();
  operationDateFrom.value = "";
  operationDateTo.value = "";
};
const handleSearch = (values: FormState) => {
  values.operationDateFrom = operationDateFrom.value;
  values.operationDateTo = operationDateTo.value;
  getTableData(values);
};
const handleFinishFailed = (errors: any) => {
  console.log(errors);
};
</script>

<style lang="scss" scoped>
.title-box {
  padding: 24px 0 24px 0;
  .number-box {
    width: 30px;
    height: 30px;
    background-color: yellowgreen;
    border-radius: 50%;
    content: "";
    text-align: center;
    line-height: 30px;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  .row-c {
    display: flex;
    align-items: center;
    .row-content {
      margin-left: 16px;
    }
  }
}
</style>