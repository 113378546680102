<template>
  <div>
    <a-modal
      v-model:open="open"
      title="单人增保"
      width="600px"
      okText="取消"
      cancelText="取消"
      :footer="null"
    >
      <a-form
        ref="formRef"
        :model="formdata"
        style="width: 600px; margin: 0 auto"
        v-bind="{
          labelCol: { span: 7 },
          wrapperCol: { span: 12 },
        }"
        :rules="rules"
        @finish="handleSubmit"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="insuredName" label="姓名">
          <a-input v-model:value="formdata.insuredName"></a-input>
        </a-form-item>
        <a-form-item name="insuredIdType" label="证件类型">
          <a-select ref="select" v-model:value="formdata.insuredIdType">
            <a-select-option v-for="foo in idTypeDicList" :key="foo.code">{{
              foo.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="insuredIdNum" label="证件号">
          <a-input
            v-model:value="formdata.insuredIdNum"
            @blur="handleFinshIdNum"
          ></a-input>
        </a-form-item>
        <a-form-item name="insuredBirth" label="出生日期">
          <a-date-picker
            v-model:value="formdata.insuredBirth"
            placeholder="请选择出生日期"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item name="insuredGender" label="性别">
          <a-select ref="select" v-model:value="formdata.insuredGender">
            <a-select-option v-for="foo in genderDicList" :key="foo.code">{{
              foo.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="socialSecurityArea" label="社保地">
          <a-input v-model:value="formdata.socialSecurityArea"></a-input>
        </a-form-item>
        <a-form-item name="relation" label="人员类别">
          <a-select
            ref="select"
            v-model:value="formdata.relation"
            @change="handleChangeRelation"
          >
            <a-select-option v-for="foo in relationDicList" :key="foo.code">{{
              foo.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="employeeName" label="员工姓名" v-if="isSelfRelaiton">
          <a-input v-model:value="formdata.employeeName"></a-input>
        </a-form-item>
        <a-form-item
          name="employeeIdNum"
          label="员工证件号"
          v-if="isSelfRelaiton"
        >
          <a-input v-model:value="formdata.employeeIdNum"></a-input>
        </a-form-item>
        <a-form-item name="branchId" label="分公司">
          <a-select ref="select" v-model:value="formdata.branchId">
            <a-select-option v-for="foo in branchDicList" :key="foo.code">{{
              foo.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="phone" label="手机号">
          <a-input v-model:value="formdata.phone"></a-input>
        </a-form-item>
        <a-form-item name="email" label="邮箱">
          <a-input v-model:value="formdata.email"></a-input>
        </a-form-item>
        <a-form-item name="employeeJobNumber" label="工号">
          <a-input v-model:value="formdata.employeeJobNumber"></a-input>
        </a-form-item>
        <a-form-item name="planPackageId" label="保障方案">
          <a-select ref="select" v-model:value="formdata.planPackageId">
            <a-select-option
              v-for="foo in planPackageDicList"
              :key="foo.code"
              >{{ foo.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item name="startTime" label="生效日期">
          <a-date-picker
            v-model:value="formdata.startTime"
            placeholder
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          v-for="(item, key) in simple"
          :name="Object.keys(item)[0]"
          :label="item[Object.keys(item)[0]]"
        >
          <a-input v-model:value="formdata[Object.keys(item)[0]]"></a-input>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 5, span: 12 }">
          <a-button type="primary" html-type="submit" style="width: 300px"
            >提交</a-button
          >
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, reactive } from "vue";
import dayjs, { Dayjs } from "dayjs";
import type { Rule } from "ant-design-vue/es/form";
import moment from "moment";
import { FormInstance, message } from "ant-design-vue";
import { addPos } from "@/api/pos";
const open = ref<boolean>(false);
const emits = defineEmits(["refresh"]);
const isSelfRelaiton = ref(true);
const dateFormat = "YYYY-MM-DD";
const props = defineProps({
  idTypeDicList: Array,
  planPackageDicList: Array,
  genderDicList: Array,
  bankDicList: Array,
  branchDicList: Array,
  relationDicList: Array,
  simple: Array,
});
const {
  idTypeDicList,
  planPackageDicList,
  genderDicList,
  bankDicList,
  branchDicList,
  relationDicList,
  simple,
} = toRefs(props);

interface FormData {
  employeeName: string;
  employeeJobNumber: string;
  branchId: string;
  email: string;
  employeeIdNum: string;
  insuredBirth: Dayjs | string;
  insuredGender: string;
  insuredIdType: string;
  insuredIdNum: string;
  insuredName: string;
  phone: string;
  planPackageId: string;
  relation: string;
  socialSecurityArea: string;
  startTime: string;
  workPlace?: string;
  affiliation?: string;
  bankBranch?: string;
  wage?: string;
  accident?: string;
  occCate?: string;
  death?: string;
  customAccident?: string;
  customDeath?: string;
  customSeriousIllness?: string;
}
const formRef = ref<FormInstance>();
const formdata = reactive<FormData>({
  employeeName: "",
  employeeJobNumber: "",
  branchId: "",
  email: "",
  employeeIdNum: "",
  insuredBirth: "",
  insuredGender: "",
  insuredIdType: "",
  insuredIdNum: "",
  insuredName: "",
  phone: "",
  planPackageId: "",
  relation: "",
  socialSecurityArea: "",
  startTime: "",
  workPlace: "",
  affiliation: "",
  bankBranch: "",
  wage: "",
  accident: "",
  occCate: "",
  death: "",
  customAccident: "",
  customDeath: "",
  customSeriousIllness: "",
});
const rules: Record<string, Rule[]> = {
  employeeName: [{ required: true, message: "请输入员工姓名" }],
  employeeJobNumber: [{}],
  branchId: [{ required: true, message: "请选择分支机构" }],
  email: [{}],
  employeeIdNum: [{ required: true, message: "请输入员工证件号" }],
  insuredBirth: [{ required: true, message: "请选择被保险人出生日期" }],
  insuredGender: [{ required: true, message: "请选择被保险人性别" }],
  insuredIdNum: [{ required: true, message: "请输入被保险人证件号" }],
  insuredIdType: [{ required: true, message: "请选择被保险人证件类型" }],
  insuredName: [{ required: true, message: "请输入被保险人姓名" }],
  phone: [{}],
  planPackageId: [{ required: true, message: "请选择保险计划" }],
  relation: [{ required: true, message: "请选择被保险人与员工关系" }],
  socialSecurityArea: [{}],
  startTime: [{ required: true, message: "请选择被保险人生效日期" }],
};
const birthDate = ref<Dayjs>();
const startDate = ref<Dayjs>();
const showModal = () => {
  open.value = true;
};

const handleSubmit = (values: any) => {
  values.insuredBirth = dayjs(values.insuredBirth).format("YYYY-MM-DD");
  values.startTime = dayjs(values.startTime).format("YYYY-MM-DD");
  addPos(values)
    .then((res: any) => {
      if (res.code == 200 && res.data.batchNumber) {
        message.success("添加成功");
        emits("refresh");
        for (let key in formdata) {
          (formdata as any)[key] = null;
        }
        open.value = false;
      } else {
        message.error(res.msg);
      }
    })
    .catch();
};

const handleChangeRelation = (data: any) => {
  if (data == "SELF") {
    isSelfRelaiton.value = false;
  } else {
    isSelfRelaiton.value = true;
  }
};
const handleFinshIdNum = (value: any) => {
  if (formdata.insuredIdType == "ID_CARD" && formdata.insuredIdNum != "") {
    let res = parseIDCard(formdata.insuredIdNum);
    formdata.insuredGender = res.gender;
    formdata.insuredBirth = dayjs(res.birthDate);
  }
};
const handleFinishFailed = (errors: any) => {
  console.log(
    "%c [ errors ]-188",
    "font-size:13px; background:pink; color:#bf2c9f;",
    errors
  );
};
defineExpose({
  showModal,
});

function parseIDCard(id: string): {
  gender: "Male" | "Female";
  birthDate: Date;
} {
  if (id.length !== 18) {
    throw new Error("Invalid ID card number. It should be 18 digits long.");
  }

  const genderCode = parseInt(id.charAt(16), 10);
  const gender = genderCode % 2 === 0 ? "FEMALE" : "MALE";

  // 转换为日期对象，注意月份是从0开始的，所以需要减1
  const year = parseInt(id.substring(6, 10), 10);
  const month = parseInt(id.substring(10, 12), 10) - 1;
  const day = parseInt(id.substring(12, 14), 10);

  const birthDate = new Date(year, month, day);

  return {
    gender,
    birthDate,
  };
}
</script>

<style lang="scss" scoped>
</style>