<template>
  <div>
    <a-modal
      v-model:open="open"
      title="员工信息"
      @ok="handleOk"
      width="600px"
      style=""
    >
      <a-form
        style="width: 400px; margin: 0 auto"
        v-bind="{
          labelCol: { span: 5 },
          wrapperCol: { span: 16 },
        }"
      >
        <a-form-item label="姓名">
          <a-input
            v-model:value="detailInfo.insuredName"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="证件类型">
          <a-select
            ref="select"
            v-model:value="detailInfo.insuredIdType"
            :disabled="true"
          >
            <a-select-option v-for="foo in idTypeDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="证件号">
          <a-input
            v-model:value="detailInfo.insuredIdNum"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="出生日期">
          <a-date-picker v-model:value="birthDate" :disabled="true" />
        </a-form-item>
        <a-form-item label="性别">
          <a-select
            ref="select"
            v-model:value="detailInfo.insuredGender"
            :disabled="true"
          >
            <a-select-option v-for="foo in genderDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="社保地">
          <a-input
            v-model:value="detailInfo.socialSecurityArea"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="人员类别">
          <a-select
            ref="select"
            v-model:value="detailInfo.relation"
            :disabled="true"
          >
            <a-select-option v-for="foo in relationDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所属员工">
          <a-input
            v-model:value="detailInfo.employeeName"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="员工证件号">
          <a-input
            v-model:value="detailInfo.employeeIdNum"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="分公司">
          <a-select
            ref="select"
            v-model:value="detailInfo.branchId"
            :disabled="true"
          >
            <a-select-option v-for="foo in branchDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="手机号">
          <a-input
            v-model:value="detailInfo.employeePhone"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input
            v-model:value="detailInfo.employeeEmail"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="工号">
          <a-input
            v-model:value="detailInfo.employeeJobNumber"
            :disabled="true"
          ></a-input>
        </a-form-item>
        <a-form-item label="保障方案">
          <a-select
            ref="select"
            v-model:value="detailInfo.planPackageId"
            :disabled="true"
          >
            <a-select-option v-for="foo in planPackageDicList" :key="foo.code">
              {{ foo.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="生效日期">
          <a-date-picker v-model:value="startDate" :disabled="true" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, ref, toRefs } from "vue";
import dayjs, { Dayjs } from "dayjs";
const open = ref<boolean>(false);
const detailInfo = ref<any>();
const props = defineProps({
  idTypeDicList: Array,
  planPackageDicList: Array,
  genderDicList: Array,
  bankDicList: Array,
  branchDicList: Array,
  relationDicList: Array,
});
const {
  idTypeDicList,
  planPackageDicList,
  genderDicList,
  bankDicList,
  branchDicList,
  relationDicList,
} = toRefs(props);
const birthDate = ref<Dayjs>();
const startDate = ref<Dayjs>();
const showModal = (record: any) => {
  detailInfo.value = record;
  console.log(
    "%c [ record ]-156",
    "font-size:13px; background:pink; color:#bf2c9f;",
    record
  );
  birthDate.value = dayjs(detailInfo.value.insuredBirth, "YYYY-MM-DD");
  startDate.value = dayjs(detailInfo.value.startTime, "YYYY-MM-DD");
  open.value = true;
};

const handleOk = (e: MouseEvent) => {
  open.value = false;
};
defineExpose({
  showModal,
});
</script>

<style lang="scss" scoped>
</style>