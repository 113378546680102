<template>
  <div class="home">
    <div class="home-content">
      <div class="top-content-c">
        <div class="top-content-left-c">
          <div>
            <a-image
              :src="contractInfo.contractLogoUrl"
              :preview="false"
              style="max-width: 150px; max-height: 60px"
            />
          </div>
          <div class="contract-c">
            <div style="font-weight: bold">
              {{ contractInfo.contractName }}
              <a-button
                type="primary"
                style="margin-left: 16px"
                @click="checkContractDetailInfo"
                >查看保障方案</a-button
              >
            </div>
            <div class="title-c">有效期：{{ contractInfo.contractDate }}</div>
          </div>
        </div>
        <div class="top-content-right-c">
          <div>
            <a-tooltip placement="topLeft">
              <template #title>截止当前时间本年度保单在保人数</template>
              <div class="title-c">
                在保人数&nbsp;<InfoCircleOutlined class="title-icon-c" />
              </div>
            </a-tooltip>
            <div class="value-c">
              {{ fmoney(contractInfo.insuredNum) }}
              <span class="value-r">人</span>
            </div>
          </div>
          <a-divider
            type="vertical"
            style="height: 60px; background-color: 42b983"
          />
          <div>
            <a-tooltip placement="topLeft">
              <template #title
                >截至当前时间系统预估保费，最终金额请以保险公司保费账单为准</template
              >
              <div class="title-c">
                预估保费&nbsp;<InfoCircleOutlined class="title-icon-c" />
              </div>
            </a-tooltip>
            <div class="value-c">
              {{ fmoney(contractInfo.estimateInsureFee)
              }}<span class="value-r">元</span>
            </div>
          </div>
          <a-divider
            type="vertical"
            style="height: 60px; background-color: 42b983"
          />
          <div>
            <a-tooltip placement="topLeft">
              <template #title
                >截至当前时间，本年度保单已赔付的理赔金额</template
              >
              <div class="title-c">
                赔付金额&nbsp;<InfoCircleOutlined class="title-icon-c" />
              </div>
            </a-tooltip>
            <div class="value-c">
              {{ fmoney(contractInfo.claimAmount)
              }}<span class="value-r">元</span>
            </div>
          </div>
          <a-divider
            type="vertical"
            style="height: 60px; background-color: 42b983"
          />
          <div>
            <a-tooltip placement="topLeft">
              <template #title
                >截至当前时间，本年度保单的即时综合赔付率</template
              >
              <div class="title-c">
                即时赔付率&nbsp;<InfoCircleOutlined class="title-icon-c" />
              </div>
            </a-tooltip>
            <div class="value-c">
              {{ contractInfo.immediatePayoutRatio
              }}<span class="value-r">%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-c">
      <div class="info-left-c">
        <a-card :bodyStyle="{ padding: '16px 24px 0px 24px' }">
          <template #title>
            <a-tooltip placement="topLeft">
              <template #title
                >截止当前时间每月增减人数，不含期初投保人员</template
              >
              月度增减人数&nbsp;<InfoCircleOutlined class="title-icon-c" />
            </a-tooltip>
          </template>
          <div id="insurence" style="width: 950px; height: 200px"></div>
        </a-card>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-card
              style="margin-top: 16px; height: 383px"
              :bodyStyle="{ padding: '16px 24px 0px 24px' }"
            >
              <template #title>
                <a-tooltip placement="topLeft">
                  <template #title>截止当前时间每月理赔案件赔付金额</template>
                  月度赔付金额&nbsp;<InfoCircleOutlined class="title-icon-c" />
                </a-tooltip>
              </template>
              <div id="claimAmount" style="width: 460px; height: 300px"></div>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card
              style="margin-top: 16px; height: 383px"
              :bodyStyle="{ padding: '16px 24px 0px 24px' }"
            >
              <template #title>
                <a-tooltip placement="topLeft">
                  <template #title>截止当前时间每月理赔案件申请数量</template>
                  月度理赔案件&nbsp;<InfoCircleOutlined class="title-icon-c" />
                </a-tooltip>
              </template>
              <div id="numberOfCase" style="width: 460px; height: 300px"></div>
            </a-card> </a-col
        ></a-row>
      </div>
      <div class="info-right-c">
        <a-card title="快捷入口" style="height: 180px">
          <a-row class="quick-entry-c">
            <a-col :span="12" class="quick-entry-col-c">
              <a-image
                src="https://img.onehome.cn/new-hr-portal/posAdd.png"
                :preview="false"
              />

              <a style="margin-left: 8px; font-weight: 900" @click="toAddPos"
                >单人增人</a
              >
            </a-col>
            <!-- <a-col :span="12"><a>收集投保信息</a></a-col> -->
          </a-row>
          <a-row class="quick-entry-c" style="margin-top: 12px">
            <a-col :span="12" class="quick-entry-col-c">
              <a-image
                src="https://img.onehome.cn/new-hr-portal/posBatchAdd.png"
                :preview="false"
              />
              <router-link :to="{ name: 'posBatchAdd' }">
                <a style="margin-left: 8px; font-weight: 900">批量增人</a>
              </router-link>
            </a-col>
          </a-row>
        </a-card>
        <a-card title="操作日志" class="operation-c">
          <div style="height: 100px; position: relative">
            <div
              style="
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              "
            >
              <a-empty
                v-if="operationLogByHome.length == 0"
                :image="Empty.PRESENTED_IMAGE_SIMPLE"
                :imageStyle="{ height: '30px' }"
              />
            </div>
            <div style="line-height: 24px">
              <div v-for="(item, index) in operationLogByHome" :key="index">
                {{ item.operationDate }}&nbsp;
                <span v-if="item.operationCode == 'EXPORT_POS'">
                  导出了
                  <a @click="getDownloadUrl(item)">{{ item.attachmentName }}</a>
                </span>
                <span v-if="item.operationCode == 'EXPORT_CLAIM'">
                  导出了
                  <a @click="getDownloadUrl(item)">{{ item.attachmentName }}</a>
                </span>
                <span v-if="item.operationCode == 'POS_REDUCE'"
                  >对{{ item.targetUserNames }}进行单人退保操作</span
                >
                <span v-if="item.operationCode == 'POS_ADD'"
                  >对{{ item.targetUserNames }}进行单人投保操作</span
                >
                <span v-if="item.operationCode == 'CHANGE_POS_LEVEL'"
                  >对{{ item.targetUserNames }}进行了层级变更</span
                >
                <span v-if="item.operationCode == 'UPLOAD_POS_BATCH_ADD'">
                  上传了批量投保文件
                  <a @click="getDownloadUrl(item)">{{ item.attachmentName }}</a>
                </span>
                <span v-if="item.operationCode == 'UPLOAD_POS_BATCH_REDUCE'">
                  上传了批量退保文件
                  <a @click="getDownloadUrl(item)">{{ item.attachmentName }}</a>
                </span>
              </div>
            </div>
            <router-link :to="{ name: 'operationLog' }">
              <a
                v-if="operationLogByHome.length != 0"
                style="
                  color: black;
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  font-size: 14px;
                "
                >查看更多</a
              >
            </router-link>
          </div>
        </a-card>
        <a-card title="帮助信息" class="helper-c">
          <div style="line-height: 28px">
            <div
              class="row-c"
              v-for="(item, index) in helperFileList"
              :key="index"
            >
              <div class="number-box">
                {{ (current - 1) * 5 + index + 1 }}
              </div>
              <a @click="handleOpenFile(item.url)"> {{ item.name }}</a>
            </div>
          </div>
          <a-pagination
            style="position: absolute; right: 16px; bottom: 20px"
            size="small"
            v-model:current="current"
            :total="helperFileListAll.length"
            :pageSize="5"
            show-less-items
            @change="handleChangeCurrent(current)"
          />
        </a-card>
      </div>
    </div>
  </div>
  <check-contract-info ref="checkContractInfo" />
</template>

<script lang="ts" setup>
import { loginApi } from "@/api/login";
import { reactive, ref, onMounted, inject, onBeforeUnmount } from "vue";
import store from "@/store";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { message, Empty } from "ant-design-vue";
import { fmoney } from "@/utils/tools";
import { getOperationLog } from "@/api/operation";
import CheckContractInfo from "@/views/modules/CheckContractInfo.vue";
import { downloadFileByPos } from "@/api/pos";
const checkContractInfo = ref(null);
import {
  echartsXAxis,
  echartsYAxis,
  echartsXAxisByPosAddAndReduce,
  echartsYAxisByPosAddAndReduce,
} from "@/utils/echartsDataTools";
import {
  getBasicInfo,
  getNormalData,
  getChartsInfo,
  getPosInfo,
} from "@/api/home";
const current = ref(1);
const helperFileList = ref([]);
const helperFileListAll = ref([]);
const router = useRouter();
type EChartsOption = echarts.EChartsOption;
var option: EChartsOption;
var option2: EChartsOption;
var option3: EChartsOption;
var option4: EChartsOption;
let echarts: any = inject("echarts");
let myChart: any = null;
let myChart3: any = null;
let myChart4: any = null;
const contractInfo = ref({
  contractName: "",
  contractDate: "",
  contractLogoUrl: "https://img.onehome.cn/new-hr-portal/5aS05YOP.png",
  claimAmount: "",
  estimateInsureFee: "",
  immediatePayoutRatio: "",
  insuredNum: "",
});
onMounted(() => {
  setTimeout(() => {
    // 在这里初始化依赖于 DOM 的库或组件
    getBasicData();
  });
});
onBeforeUnmount(() => {
  if (myChart) {
    myChart.dispose();
    myChart = null;
  }
  if (myChart3) {
    myChart3.dispose();
    myChart3 = null;
  }
  if (myChart4) {
    myChart4.dispose();
    myChart4 = null;
  }
});
const operationLogByHome = ref<any[]>([]);
const checkContractDetailInfo = () => {
  if (checkContractInfo.value) checkContractInfo.value.showModal();
};

const claimAmountCard = [
  {
    key: "claimAmount",
    tab: "理赔金额",
  },
  {
    key: "numberOfCase",
    tab: "案件数量",
  },
];

const getBasicData = () => {
  getBasicInfo()
    .then((res) => {
      contractInfo.value.contractName = res.data.contractBriefInfo.contractName;
      contractInfo.value.contractLogoUrl = res.data.logoUrl
        ? res.data.logoUrl
        : "https://img.onehome.cn/new-hr-portal/5aS05YOP.png";
      contractInfo.value.contractDate =
        res.data.contractBriefInfo.contractStartTime +
        "至" +
        res.data.contractBriefInfo.contractEndTime;
      helperFileListAll.value = res.data.helperFileList;
      for (let i = 0; i <= 4 && helperFileListAll.value[i] != undefined; i++) {
        helperFileList.value.push(helperFileListAll.value[i]);
      }
    })
    .catch();
  getNormalData()
    .then((res) => {
      contractInfo.value.claimAmount = String(res.data.claimAmount);
      contractInfo.value.estimateInsureFee = String(res.data.estimateInsureFee);
      contractInfo.value.immediatePayoutRatio = String(
        (res.data.immediatePayoutRatio * 100).toFixed(2)
      );
      contractInfo.value.insuredNum = String(res.data.insuredNum);
    })
    .catch();
  getChartsInfo()
    .then((res) => {
      setTimeout(() => {
        const claimAmountEchartsDom = document.getElementById("claimAmount");
        if (claimAmountEchartsDom) {
          myChart3 = echarts.init(claimAmountEchartsDom);
          option3 = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            xAxis: {
              type: "category",
              data: echartsXAxis(res.data.claimAmountStatistics),
              axisTick: {
                alignWithLabel: true,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                show: false,
              },
            },
            grid: {
              left: "3%",
              top: 20,
              bottom: 30,
              containLabel: true,
            },
            series: [
              {
                data: echartsYAxis(res.data.claimAmountStatistics),
                type: "bar",
                showBackground: true,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#42b983" },
                    { offset: 0.5, color: "#A8DBB4" },
                    { offset: 1, color: "#DAF0E0" },
                  ]),
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#DAF0E0" },
                      { offset: 0.7, color: "#8ED0A0" },
                      { offset: 1, color: "#42b983" },
                    ]),
                  },
                },
                barWidth: "60%",
              },
            ],
          };
          option3 && myChart3.setOption(option3);

          myChart4 = echarts.init(document.getElementById("numberOfCase"));
          option4 = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            xAxis: {
              type: "category",
              data: echartsXAxis(res.data.claimCaseStatistics),
              axisTick: {
                alignWithLabel: true,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                show: false,
              },
            },
            grid: {
              left: "3%",
              top: 20,
              bottom: 30,
              containLabel: true,
            },
            series: [
              {
                data: echartsYAxis(res.data.claimCaseStatistics),
                type: "bar",
                showBackground: true,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#1677ff" },
                    { offset: 0.5, color: "#69b1ff" },
                    { offset: 1, color: "#bae0ff" },
                  ]),
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#bae0ff" },
                      { offset: 0.7, color: "#4096ff" },
                      { offset: 1, color: "#1677ff" },
                    ]),
                  },
                },
              },
            ],
          };
          option4 && myChart4.setOption(option4);
        }
      }, 200);
    })
    .catch();
  getPosInfo()
    .then((res) => {
      setTimeout(() => {
        const insurenceEchartsDom = document.getElementById("insurence");
        if (insurenceEchartsDom) {
          myChart = echarts.init(insurenceEchartsDom);
          option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            legend: {
              show: true,
              right: "50px",
              orient: "vertical",
              type: "scroll",
              top: "middle",
            },
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                data: echartsXAxisByPosAddAndReduce(
                  res.data.addMonthStatistics,
                  res.data.reduceMonthStatistics
                ),
                axisLine: {
                  onZero: false,
                },
              },
            ],
            yAxis: {
              type: "value",
              splitLine: {
                show: false,
              },
            },
            grid: {
              bottom: 30,
              borderWidth: 2,
              top: "30px",
              left: "50px",
              right: "20%",
            },
            series: [
              {
                name: "月度增人",
                data: echartsYAxisByPosAddAndReduce(
                  res.data.addMonthStatistics,
                  res.data.reduceMonthStatistics
                ),
                type: "line",
                smooth: true,
                lineStyle: {
                  width: 0,
                },
                showSymbol: false,
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#1677ff",
                    },
                    {
                      offset: 1,
                      color: "#bae0ff",
                    },
                  ]),
                },
              },
              {
                name: "月度减人",
                data: echartsYAxisByPosAddAndReduce(
                  res.data.reduceMonthStatistics,
                  res.data.addMonthStatistics
                ),
                type: "line",
                smooth: true,
                lineStyle: {
                  width: 0,
                },
                showSymbol: false,
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#42b983",
                    },
                    {
                      offset: 1,
                      color: "#DAF0E0",
                    },
                  ]),
                },
              },
            ],
          };
          option && myChart.setOption(option);
        }
      }, 200);
    })
    .catch();
  getOperationLog({})
    .then((reso) => {
      let data = reso.data.dataList;
      for (let i = 0; data.length > 2 ? i <= 2 : i < data.length; i++) {
        operationLogByHome.value.push(data[i]);
      }
    })
    .catch();
};
const getDownloadUrl = async (record: any) => {
  let paramater = {
    id: record.attachmentId,
    duration: 600,
  };
  let res: any = await downloadFileByPos(paramater);
  if (res.code == 200) {
    let a = document.createElement("a");
    a.href = res.data;
    a.click();
    message.success("下载成功");
  } else {
    message.error(res.msg);
  }
};
const handleOpenFile = (url: string) => {
  window.open(url);
};
const handleChangeCurrent = (pageNum: any) => {
  helperFileList.value = [];
  for (
    let i = pageNum * 5 - 5;
    i <= pageNum * 5 - 1 && helperFileListAll.value[i] != undefined;
    i++
  ) {
    helperFileList.value.push(helperFileListAll.value[i]);
  }
};
const toAddPos = () => {
  store.dispatch("setIsShowAddPosModal", true);
  router.push({ name: "posList" });
};
</script>

<style lang="scss" scoped>
div img {
  cursor: pointer;
  transition: all 0.6s;
}

div img:hover {
  transform: scale(1.2);
}
.home {
  .home-content {
    width: 100%;
    background-color: #ffffff;
    .top-content-c {
      margin: 0 auto;
      max-width: 1440px;
      height: 120px;
      display: flex;
      line-height: 30px;
      align-items: center;
      justify-content: space-between;
      .top-content-left-c {
        display: flex;
        align-items: center;
        .contract-c {
          text-align: left;
          margin-left: 16px;
        }
      }
      .top-content-right-c {
        text-align: left;
        width: 540px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .info-c {
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    margin-top: 16px;
    text-align: left;
    .info-left-c {
      height: 680px;
      width: 980px;
    }
    .info-right-c {
      height: 680px;
      margin-left: 16px;
      width: 444px;
      .quick-entry-c {
        text-align: center;
        height: 28px;
        .quick-entry-col-c {
          display: flex;
          left: 0;
          align-items: center;
        }
        a {
          font-size: 16px;
          line-height: 28px;
          color: #42b983;
        }
      }
      .operation-c {
        height: 200px;
        margin-top: 16px;
      }
      a {
        color: #42b983;
      }
    }
  }
}
.title-c {
  font-size: 14px;
}
.title-icon-c {
  opacity: 0.45;
}
.value-c {
  color: #42b983;
  font-weight: bold;
  font-size: 20px;
  .value-r {
    font-size: 14px;
  }
}
.helper-c {
  margin-top: 16px;
  position: relative;
  height: 260px;
  .row-c {
    display: flex;
    align-items: center;
    .number-box {
      width: 20px;
      height: 20px;
      background-color: #bfbfbf;
      border-radius: 50%;
      content: "";
      text-align: center;
      line-height: 20px;
      color: white;
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: black !important;
      margin-left: 16px;
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>