<template>
  <div>
    <a-modal
      v-model:open="open"
      title="保障方案"
      @ok="handleOk"
      width="100%"
      style="max-width: 1440px"
    >
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane
          v-for="(item, index) in tabsInfo"
          :key="index"
          :tab="item.name"
        >
          <a-row>
            <div v-for="(Oitem, Oindex) in item.liabilityList" :key="Oindex">
              <a-col>
                <div style="width: 180px">
                  <div style="font-weight: bold">
                    {{ insurancce_liability[Oitem.type] }}
                  </div>
                  <div v-if="Oitem.type != 'HOSPITALIZATION_ALLOWANCE'">
                    <span
                      v-if="
                        Oitem.insuranceCoverageType == '' ||
                        Oitem.insuranceCoverageType == 'FIXED'
                      "
                    >
                      保额：{{ fmoney(Oitem.insuranceCoverage) }}
                    </span>
                    <span
                      v-if="Oitem.insuranceCoverageType == 'MONTHLY_SALARY'"
                    >
                      保额：{{ fmoney(Oitem.monthlySalaryMultiple) }}倍月薪
                    </span>
                    <span v-if="Oitem.insuranceCoverageType == 'CUSTOMIZE'">
                      <a-tooltip placement="topLeft">
                        <template #title
                          >每位员工配置不同保额，请查看人员管理了解详细信息。</template
                        >
                        <div class="title-c">
                          保额：&nbsp;<InfoCircleOutlined
                            class="title-icon-c"
                          />
                        </div>
                      </a-tooltip>
                    </span>
                  </div>
                  <div v-if="Oitem.type in liabilityGroup">
                    免赔额：{{ fmoney(Oitem.deductible) }}
                  </div>
                  <div v-if="Oitem.type in liabilityGroup">
                    赔付比例：{{ Oitem.maxPayoutRatio }}%
                  </div>
                  <div v-if="Oitem.type == 'HOSPITALIZATION_ALLOWANCE'">
                    普通住院补贴金额{{ Oitem.generalWardSubsidy }}元/天
                  </div>
                  <div v-if="Oitem.type == 'HOSPITALIZATION_ALLOWANCE'">
                    ICU住院补贴金额{{ Oitem.icuWardSubsidy }}元/天
                  </div>
                </div>
              </a-col>
            </div>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { getContractDetailInfo } from "@/api/home";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { fmoney } from "@/utils/tools";
import { insurancce_liability, liabilityGroup } from "@/config/config";
const open = ref<boolean>(false);
const tabsInfo = ref<Array<any>>([]);
const activeKey = ref();
const showModal = () => {
  activeKey.value = 0;
  open.value = true;
  getContractDetailInfo()
    .then((res) => {
      if (res.data.planPackageBriefList != null && tabsInfo.value.length == 0) {
        res.data.planPackageBriefList.map((foo: any) => {
          tabsInfo.value.push({
            name: foo.name,
            key: foo.level,
            liabilityList: foo.liabilityBriefList,
          });
        });
      }
    })
    .catch();
};

const handleOk = (e: MouseEvent) => {
  open.value = false;
};
defineExpose({
  showModal,
});
</script>

<style lang="scss" scoped>
</style>