import { axios } from '@/utils/request'
import api from './index'

export function getBasicInfo() {
  return axios({
    url: api.getBasicInfo,
    method: 'post',
  })
}

export function getContractDetailInfo() {
  return axios({
    url: api.getContractDetailInfo,
    method: 'post',
  })
}

export function getNormalData() {
  return axios({
    url: api.getNormalData,
    method: 'post',
  })
}

export function getPosInfo() {
  return axios({
    url: api.getPosInfo,
    method: 'post',
  })
}


export function getChartsInfo() {
  return axios({
    url: api.getChartsInfo,
    method: 'post',
  })
}

export function getDownloadUrl(parameter: object) {
  return axios({
    url: api.getDownloadUrl,
    method: 'post',
    data: parameter
  })
}
