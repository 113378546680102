import axios from 'axios';
import { ACCESS_TOKEN } from '@/config/config'
import { message, notification } from 'ant-design-vue'
import store from '@/store';
import router from '@/router'
import qs from 'qs'
import api from '@/api'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60 * 1000,
})


const err = (error: any) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        notification.error({
          message: '错误',
          description: error.response.data.resp_msg || '请求出现错误，请稍后再试',
          duration: 4
        })
        store.dispatch('logOut').then(() => {
        })
        break;
      case 403:
        notification.error({
          message: '错误',
          description: '没有权限',
          duration: 4
        })
        break;
      case 500:
        notification.error({
          message: '错误',
          description: '系统错误，请联系客服',
          duration: 4
        })
        break;
      case 503:
        notification.error({
          message: '错误',
          description: '登录超时',
          duration: 4
        })
        break;
      default:
        notification.error({
          message: '错误',
          description: '未知错误',
          duration: 4
        })
    }
  } else {
    notification.error({
      message: '错误',
      description: '请求出现错误，请稍后再试',
      duration: 4
    })
    store.dispatch('logOut').then(() => {
      router.push({
        name: 'login'
      })
    }).catch()
  }
  return Promise.reject(error.response.data)
}

request.interceptors.request.use((config: any) => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  // 表单上传文件，header头判断
  const fileUpload: any = []// 需要上传的url
  const putDate: any = [api.downLoadTemplate, api.uploadForPosBatch]
  config.headers = {
    'Content-Type': 'application/json'
  }
  if (fileUpload.indexOf(config.url) > -1) {
    config.headers = {
      'Content-Type': 'multipart/form-data'
    }
  } else if (putDate.indexOf(config.url) > -1) {
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    if (config.method === 'post') {
      config.data = qs.stringify(config.data)
    }
  }
  if (token) {
    config.headers[ACCESS_TOKEN] = `Bearer ${token}`
  }
  // const configurl = config.url
  if (config.url.includes('oauth/user/token')) {
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Credentials'] = true
  }




  return config
}, err)

request.interceptors.response.use((response) => {
  const res = response.data
  if (parseInt(response.data.status) === 0) {
    return Promise.reject(res)
  } else {
    return res
  }
}, err)

export {
  request as axios
}