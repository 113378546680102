<template>
  <div>
    <a-modal
      v-model:open="open"
      title="导出记录"
      width="800px"
      okText="确定"
      cancelText="取消"
      :footer="null"
    >
      <span style="color: #000000a6; font-size: 12px">
        此导出文件需使用密码打开，初始密码为123456。如忘记密码，请点击页面右上角头像选择重置下载密码。
      </span>
      <a-table
        :dataSource="dataSource"
        style="margin-top: 4px"
        :columns="columns"
        :pagination="{
          current: currentPage,
          onChange: onChangePage,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          total: totalData,
          pageSize: pageSizeConfig,
        }"
        :loading="loading"
      >
        <template #bodyCell="{ column, record }">
          <template
            v-if="column.key === 'fileName' && record.state == 'SUCCESS'"
          >
            <a style="color: #42b983" @click="handleDownload(record)">{{
              record.fileName
            }}</a>
          </template>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, reactive, onMounted } from "vue";
import dayjs, { Dayjs } from "dayjs";
import type { Rule } from "ant-design-vue/es/form";
import moment from "moment";
import { useRoute } from "vue-router";
import { FormInstance, message } from "ant-design-vue";
import { exportRecordList } from "@/api/pos";
import { getDownloadUrl } from "@/api/home";
const open = ref<boolean>(false);

const showModal = () => {
  open.value = true;
  getTableData();
};

const handleSubmit = (values: any) => {};
const handleFinishFailed = (errors: any) => {
  console.log(
    "%c [ errors ]-188",
    "font-size:13px; background:pink; color:#bf2c9f;",
    errors
  );
};
defineExpose({
  showModal,
});
const dataSource = ref<Array<any>>([]);
const loading = ref<boolean>(false);
const route = useRoute();
const checkPosDetailInfo = ref(null);
const currentPage = ref(1);
const pageSizeConfig = ref(5);
const totalData = ref(0);
const columns = ref<Array<any>>([
  {
    title: "文件名称",
    dataIndex: "fileName",
    key: "fileName",
  },
  {
    title: "操作人",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "操作时间",
    dataIndex: "operationTime",
    key: "operationTime",
  },
  {
    title: "状态",
    dataIndex: "stateZh",
    key: "stateZh",
  },
]);

const getTableData = async (data: any = {}) => {
  loading.value = true;
  data.type = "POS";
  let res: any = await exportRecordList(data);
  if (res.code == 200) {
    loading.value = false;
    dataSource.value = res.data.dataList;
    totalData.value = res.data.total;
    checkFileState(res.data.dataList);
  } else {
    message.error(res.msg);
  }
};

const onChangePage = (pageNum: number, pageSize: number) => {
  let data = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  pageSizeConfig.value = pageSize;
  currentPage.value = pageNum;
  getTableData(data);
};

const handleDownload = async (record: any) => {
  let params = {
    id: record.attachmentId,
    duration: 60 * 10,
  };
  let res = await getDownloadUrl(params);
  let a = document.createElement("a");
  a.href = res.data;
  a.click();
};

const checkFileState = (list: Array<any>) => {
  list.forEach((e) => {
    if (e.state == "EXPORTING") {
      setTimeout(() => {
        getTableData();
      }, 3000);
    }
  });
};
</script>

<style lang="scss" scoped>
</style>