
import { RouteRecordRaw } from 'vue-router'

const routes = {
  state: {
    routes: [],
    routerList: []
  },
  getters: {
    GET_ROUTE(state: any) {
      return state.routes
    },
    GET_ROUTERLIST(state: any) {
      return state.routerList
    }
  },
  mutations: {
    SET_ROUTE(state: any, routes: RouteRecordRaw[]) {
      state.routes = routes
    },
    SET_ROUTERLIST(state: any, routes: []) {
      state.routerList = routes
    }
  },
  actions: {
    generateRoutes(context: any, routesData: any) {
      context.commit('SET_ROUTE', routesData)
    },
    generateRouterList(context: any, routesData: Array<string>) {
      context.commit('SET_ROUTERLIST', routesData)
    }
  },


}

export default routes
