const ip = {
  base: process.env.VUE_APP_BASE_URL,
  normal: process.env.VUE_APP_BASE_URL,
}

const prefix = {
  login: ip.base,
  normal: ip.normal
}

const api = {
  getVerifyCode: `${prefix.login}login/send-sms-verify-code`,
  login: `${prefix.login}login/by-phone`,
  handleChangeUser: `${prefix.login}login/switch-account`,
  getPermission: `${prefix.login}user/auth-menu`,
  changePassword: `${prefix.login}user/change-password`,
  getUserInfo: `${prefix.login}user/user-info`,

  getBasicInfo: `${prefix.login}portal/basic-info`,
  getContractDetailInfo: `${prefix.login}portal/contract-detail-info`,
  getNormalData: `${prefix.login}portal/statistics/normal-data`,
  getPosInfo: `${prefix.login}portal/statistics/add-reduce-pos`,
  getChartsInfo: `${prefix.login}portal/statistics/claim-amount`,

  getPosList: `${prefix.login}pos/list`,
  updatePos: `${prefix.login}pos/update`,
  getDic: `${prefix.login}portal/drop-down-param-dic`,
  addPos: `${prefix.login}pos/add`,
  reducePos: `${prefix.login}pos/reduce`,
  revokePos: `${prefix.login}pos/withdrawal`,
  changePlan: `${prefix.login}pos/change-plan`,
  uploadForPosBatch: `${prefix.login}attachment/upload`,
  downLoadTemplate: `${prefix.login}pos/batch/download-template`,
  importExcelData: `${prefix.login}pos/batch/excel-submit`,
  posExport: `${prefix.login}pos/list-export`,
  exportRecordList: `${prefix.login}export-log/list`,
  getAttachmentList: `${prefix.login}attachment/list`,
  downloadFileByPos: `${prefix.login}attachment/download-file-by-url`,


  claimInquiryList: `${prefix.login}claim/list`,
  claimExport: `${prefix.login}claim/list-export`,
  getOperationLog: `${prefix.login}operation-log/list`,
  getDownloadUrl: `${prefix.login}attachment/download-file-by-url`,
  getDataStatisticsIds: `${prefix.login}dataease/param-info`,

  //flex
  getFlexEmployeeList: `${prefix.login}flex/employee-list`,
  exportFlexEmployeeList: `${prefix.login}flex/employee-list-export`,
  getBoughtRecordList: `${prefix.login}flex/order-detail-list`
}


export default api
