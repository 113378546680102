<template>
  <a-config-provider
    :locale="zhCN"
    :theme="{
      token: {
        colorPrimary: '#42b983',
      },
    }"
  >
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>
<script lang="ts" setup>
import * as echarts from "echarts";
import { provide } from "vue";
import { ref } from "vue";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from "ant-design-vue/es/locale/zh_CN";

dayjs.locale("zh-cn");

// echarts
provide("echarts", echarts);
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  height: 100%;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
