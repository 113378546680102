<template>
  <div style="max-width: 1440px; margin: 0 auto; margin-top: 32px">
    <iframe :src="iframeUrl" width="100%" height="800px" style="border: none" />
  </div>
</template>

<script lang="ts" setup>
//弹福
import { getDataStatisticsIds } from "@/api/dataStatistics";
import { onMounted, ref } from "vue";
import { encryptedData, decryptedData } from "@/utils/rsa";
const iframeUrl = ref("https://bi.onehome.cn/link/KzPDkvKI");
onMounted(() => {
  getIds();
  let a =
    "mh7rsrNS8V9PoujxKB%2FAPg5jODwnAgO9AYPm%2BA6eL8lbTVlgAp7DjF7hUzL9jZ2VgEkDEQXxDQaA4bo0%2BXKkhZJUcMmDbEZZ7CI467SrcdzstoHxhOs6OpnHIhz4KyDbSgbYjeX7dgzrI59o1Ld3US6YdZuV2sZb5P7ok2uItPo%3D";
  let b = decryptedData(decodeURIComponent(a));
});

const getIds = async () => {
  let res: any = await getDataStatisticsIds({});
  if (res.code == 200) {
    let params = `{"flex_id":${res.data.welfareId}}`;
    let encryptedParams = encodeURIComponent(encryptedData(params));
    iframeUrl.value = iframeUrl.value + "?attachParams=" + encryptedParams;
  }
};
</script>

<style lang="scss" scoped>
</style>