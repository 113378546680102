
import { RouteRecordRaw } from 'vue-router'

const routes = {
  state: {
    iwShowAddPosModal: false,
  },
  getters: {
    GET_IS_SHOW_ADD_POS_MODAL(state: any) {
      return state.iwShowAddPosModal
    },
  },
  mutations: {
    SET_IS_SHOW_ADD_POS_MODAL(state: any, iwShowAddPosModal: boolean) {
      state.iwShowAddPosModal = iwShowAddPosModal
    },
  },
  actions: {
    setIsShowAddPosModal(context: any, iwShowAddPosModal: boolean) {
      context.commit('SET_IS_SHOW_ADD_POS_MODAL', iwShowAddPosModal)
    },
  },


}

export default routes
