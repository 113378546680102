<template>
  <div>
    <a-config-provider :theme="{
      token: {
        colorPrimary: '#00b96b',
      },
    }">
      <div></div>
      <a-space wrap>
        <a-button type="primary">Primary Button</a-button>
        <a-button>Default Button</a-button>
        <a-button type="dashed">Dashed Button</a-button>
        <a-button type="text">Text Button</a-button>
        <a-button type="link">Link Button</a-button>
        <a-radio>Radio</a-radio>
        <a-checkbox>Checkbox</a-checkbox>
      </a-space>
    </a-config-provider>
  </div>
</template>

<script lang="ts">
export default {

}
</script>

<style></style>